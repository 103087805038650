.home-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 300px;
}

.home-image-container {
    position: relative;
    margin-bottom: 300px;
}
.crazytag{
    display:none;
}

.home-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.home-content{
    position: absolute;
    top: 90%;
    left: 57%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    z-index: 5;
}

.home-left-content {
    max-width: 700px;
    transform: translateX(-30px);
}

.home-heading {
    font-size: 25px;
    transform: translateY(30px);
    color: #000;
}

.home-subheading {
    font-size: 18px;
    margin-bottom: 15px;
    color: #000;
}

.home-paragraph {
    font-size: 18px;
    color: #000;
}
.home-paragraph ul {
    list-style-type: disc; /* This makes the bullet points disc-shaped */
    padding-left: 20px; /* Adds spacing between the bullets and the text */
  }
  
  .home-paragraph li {
    margin-bottom: 10px; /* Adds space between each bullet point */
    font-size: 18px; /* Adjust the font size as per your design */
    line-height: 1.5; /* Increases the line height for better readability */
  }
  
.home-right-content {
    position: relative;
    width: 1000px;
}

.video-container {
    position: relative;
    width: 90%;
    max-width: 800px;
    /* margin: 0 1px; */
  }
  
  .home-video {
    width: 100%;
    height: 340px;
    object-fit: fill;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1;
    display: block;
  }
  

.stats-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 0;
    background: linear-gradient(to right, #ffa500, #4a00e0);
    color: white;
    text-align: center;
    margin-top: 100px;
  }

 .stat-item {
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .stat-item h1 {
    font-size: 2.8rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .stat-item p {
    font-size: 1rem;
    margin-top: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffffff;
  }
  
  .stat-item:hover {
    transform: scale(1.05);
  }
  .container {
    display: flex;
    align-items: flex-start; /* Align items at the start of the container */
    padding: 40px; /* Adjust as needed for spacing */
    transform: translateY(-40px);
}

.blue-car-image {
    max-width: 35%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Space between image and content */
}

.content {
    max-width: 60%; /* Adjust width as needed */
    margin-left: 20px;
}

h2 {
    margin-top: 50px;
    font-size: 25px; /* Adjust heading size as needed */
    margin-bottom: 10px; /* Space below heading */
}

.bullet-points {
    list-style-type: disc; /* Use bullet points */
    padding-left: 20px; /* Indent bullet points */
    font-size: 18px;
}

.bullet-points li {
    margin-bottom: 20px; /* Space between bullet points */
}

.circle-container {
    position: relative; /* For absolute positioning of child elements */
    width: 550px; /* Adjust size of the circle (smaller) */
    height: 550px; /* Adjust size of the circle (smaller) */
    margin: 0 auto; /* Center the container horizontally */
    transform: translateY(-70px);
    margin-bottom: -200px;
}

.circle {
    position: relative; /* To position the car image */
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Make the background circular */
    overflow: hidden; /* Hide overflow of the circular background */
}

.circle-image {
    width: 100%; /* Make sure the circle image fills the container */
    height: 100%;
    object-fit: cover; /* Cover the entire circle */
}

.car-image {
    position: absolute; /* Positioning relative to the circle */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset the image to center */
    width: 90%; /* Adjust the car image size to fit well in the smaller circle */
    z-index: 1; /* Ensure the car is above the circle */
}

.cards-container {
    position: absolute; /* Position the cards */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset for centering */
    width: 150px; /* Same as the circle's diameter */
    height: 150px; /* Same as the circle's diameter */
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center the cards */
    align-items: center; /* Center the cards */
}

.card {
    position: absolute; /* Position cards absolutely */
    background-color: #fff; /* Card background color */
    border: 1px solid #ccc; /* Card border */
    border-radius: 8px; /* Rounded corners */
    width: 300px; /* Card width */
    height: 100px; /* Card height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow effect */
    display: flex; /* Center content within cards */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
}

/* Positioning cards in a circular layout */
.card:nth-child(1) { top: 0; left: -225%; transform: translate(-50%, -100%); } /* Top */
.card:nth-child(2) { top: 140%; left: -325%; transform: translate(0, -50%); } /* Right */
.card:nth-child(3) { top: -30%; left: 427%; transform: translate(-100%, -50%); } /* Left */
.card:nth-child(4) { bottom: 0; left: 327%; transform: translate(-50%, 100%); } /* Bottom */
.card:nth-child(5) { top: -50%; left: 1173%; transform: translate(-610%, 440%); } /* Center (optional) */

.car-section {
    display: flex; /* Use Flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100vh; /* Full viewport height */
    margin: 20px 0; /* Optional: margin for spacing */
}

/* .orangecar-image {
    max-width: 50%; 
    height: auto; 
} */
/* .dooh-section {
    background-color: #ffa500;
    color: black;
    padding: 70px;
    position: relative; 
    margin-bottom: -460px;
    transform: translateY(-70px);
} */

/* .dooh-heading {
    color: white;
    font-size:25px;
    transform: translateY(-40px);
}

.dooh-section p {
    font-size: 18px; 
    margin-bottom: 20px;
    transform: translateY(-30px);
}
.dooh-section ul {
    margin-bottom: 20px;
    padding-left: 20px; 
    transform: translateY(-35px);
} */

/* .dooh-section li {
    font-size: 18px; 
    margin-bottom: 10px; 
} */
/* .curve-image {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(70px);
    transform: translateY(-0px);
    width: 433px; 
    height: auto;    
} */
.polo{
    width: 50%;
    margin-top: 150px;
}

@media (max-width: 1550px) {
    .home-left-content {
        width: 110%;
        margin-left: -200px;
    }
}

@media (max-width: 1450px) {
    .home-left-content {
        width: 100%;
        margin-left: -70px;
    }
}

@media (max-width: 1250px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-left-content{
        width: 80%;
        margin-left: 10px;
    }

    .video-container{
        width: 650px;
        height: 630px;
        margin-top: 100px;
        /* margin-right: -70px; */
    }

    .home-video{
        width: 80%;
        height: 90%;
    }

      .play-button{
        margin-top: -1%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stat-item h1 {
        font-size: 2.5rem;
    }

    .stat-item p {
        font-size: 1rem;
    }

        .card:nth-child(1) {
            top: 0;
            left: -215%;
            transform: translate(-50%, -100%);
        }
    
        .card:nth-child(2) {
            top: 140%;
            left: -315%;
            transform: translate(0, -50%);
        }

        .card:nth-child(3) {
            top: -30%;
            left: 417%;
            transform: translate(-100%, -50%);
        }
    
        .card:nth-child(4) {
            bottom: 0;
            left: 317%;
            transform: translate(-50%, 100%);
        }
    
        .card:nth-child(5) {
            top: -50%;
            left: 1173%;
            transform: translate(-610%, 440%);
        }

    .orangecar-image{
        margin-top: -30%;
    }

    .dooh-section{
        margin-top: -25%;
    }
}

@media (max-width: 1140px) {
    .home-heading {
            font-size: 18px;
        }
    
        .home-subheading {
            font-size: 24px;
        }

        .home-content{
            padding-left: 130px;
            margin-top: 5%;
            margin-right: 20px;
        }
    
        .home-left-content {
            width: 55%;
        }
    
        .video-container {
            width: 650px;
            height: 670px;
            margin-top: 100px;
            /* margin-right: -70px; */
        }
    
        .home-video {
            width: 80%;
            height: 90%;
        }
    
        .play-button {
            margin-top: -3%;
            margin-left: -7%;
            cursor: pointer;
        }

        .stats-container{
            margin-top: 35%;
        }
    
        .stat-item h1 {
            font-size: 2.5rem;
        }
    
        .stat-item p {
            font-size: 0.8rem;
        }

        .circle-image{
            width: 80%;
            height: 80%;
            margin-left: 10%;
        }

        .car-image{
            width: 80%;
            height: 60%;
            margin-top: -10%;
        }
    
        .card:nth-child(1) {
            top: 0;
            left: -180%;
            transform: translate(-50%, -100%);
        }
    
        .card:nth-child(2) {
            top: 140%;
            left: -260%;
            transform: translate(0, -50%);
        }
    
        .card:nth-child(3) {
            top: -30%;
            left: 385%;
            transform: translate(-100%, -50%);
        }
    
        .card:nth-child(4) {
            bottom: 0;
            left: 267%;
            transform: translate(-50%, 100%);
        }
    
        .card:nth-child(5) {
            top: -90%;
            left: 1173%;
            transform: translate(-610%, 440%);
        }
    
        .orangecar-image {
            margin-top: -47%;
        }
    
        .dooh-section {
            margin-top: -35%;
        }

        .curve-image{
            width: 37%;
            height: 47%;
        }

        .polo{
            width: 50%;
            height: 500px;
        }
}

@media (max-width: 1040px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        padding-left: 200px;
        margin-top: 10%;
        margin-right: 20px;
    }

    .home-left-content {
        width: 60%;
    }

    .video-container {
        width: 500px;
        height: 600px;
        margin-top: 70px;
        /* margin-right: -70px; */
    }

    .home-video {
        width: 80%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 45%;
        justify-content: space-evenly;
    }

    .stat-item h1 {
        font-size: 2.5rem;
    }

    .stat-item p {
        font-size: 0.9rem;
    }

    .circle-image {
        width: 75%;
        height: 75%;
        margin-left: 15%;
    }

    .car-image {
        width: 70%;
        height: 60%;
        margin-top: -15%;
        margin-left: 3%;
    }

    .card:nth-child(1) {
        top: 0;
        left: -155%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 140%;
        left: -240%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: -30%;
        left: 380%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        bottom: 0;
        left: 260%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -90%;
        left: 1173%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        margin-top: -47%;
    }

    .dooh-section {
        margin-top: -35%;
    }

    .curve-image {
        width: 37%;
        height: 47%;
    }

        .polo {
            margin-top: 10%;
            width: 50%;
            height: 600px;
        }
}

@media (max-width: 990px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        padding-left: 290px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        width: 60%;
    }

    .video-container {
        width: 450px;
        height: 650px;
        margin-top: 100px;
    }

    .home-video {
        width: 80%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 50%;
        justify-content: space-evenly;
    }

    .stat-item h1 {
        font-size: 2.4rem;
    }

    .stat-item p {
        font-size: 0.8rem;
    }

    .circle-image {
        width: 70%;
        height: 70%;
        margin-left: 20%;
    }

    .car-image {
        width: 60%;
        height: 50%;
        margin-top: -15%;
        margin-left: 5%;
    }

    .card:nth-child(1) {
        top: 0;
        left: -130%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 110%;
        left: -230%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: -30%;
        left: 370%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 10%;
        bottom: 0;
        left: 260%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -130%;
        left: 1183%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        margin-top: -67%;
    }

    .dooh-section {
        margin-top: -50%;
    }

    .curve-image {
        width: 50%;
        height: 50%;
    }

        .polo {
            margin-top: 8%;
            width: 50%;
            height: 600px;
        }
}

@media (max-width: 890px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 290px;
        margin-top: 20%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 50%;
        margin-left: -30%;
        width: 90%;
    }

    .video-container {
        width: 700px;
        height: 500px;
        margin-top: 50px;
    }

    .home-video {
        width: 90%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 105%;
        justify-content: space-evenly;
    }

    .stat-item h1 {
        font-size: 2.2rem;
    }

    .stat-item p {
        font-size: 0.6rem;
    }

    .circle-image {
        width: 50%;
        height: 50%;
        margin-left: 30%;
    }

    .car-image {
        width: 50%;
        height: 40%;
        margin-top: -25%;
        margin-left: 5%;
    }

    .card:nth-child(1) {
        top: -50%;
        left: -100%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 30%;
        left: -200%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: -80%;
        left: 345%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: -70%;
        bottom: 0;
        left: 240%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -210%;
        left: 1193%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        margin-top: -110%;
        margin-left: 5%;
    }

    .dooh-section {
        margin-top: -77%;
    }

    .curve-image {
        width: 50%;
        height: 50%;
    }

        .polo {
            margin-top: 15%;
            width: 50%;
            height: 600px;
        }
}

@media (max-width: 790px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 290px;
        margin-top: 20%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 70%;
        margin-left: -30%;
        width: 70%;
        padding: 5%;
    }

    .video-container {
        width: 650px;
        height: 500px;
        margin-top: 10px;
        margin-left: 40px;
    }

    .home-video {
        width: 85%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 145%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 3rem;
    }

    .stat-item p {
        font-size: 1.7rem;
    }

    .container{
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        width: 100%;
        height: 100%;
        margin-left: 5%;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 10%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 90%;
        left: -100%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 195%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 75%;
        bottom: 0;
        left: 100%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -60%;
        left: 1120%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        margin-top: -60%;
        margin-left: 5%;
    }

    .dooh-section {
        margin-top: -57%;
    }

    .curve-image {
        width: 50%;
        height: 45%;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 690px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 20%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 70%;
        margin-left: -30%;
        width: 67%;
        padding: 7%;
    }

    .video-container {
        width: 600px;
        height: 500px;
        margin-top: 10px;
        margin-left: 70px;
    }

    .home-video {
        width: 87%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 165%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 3rem;
    }

    .stat-item p {
        font-size: 1.7rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        width: 100%;
        height: 100%;
        margin-left: 5%;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 10%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 90%;
        left: -100%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 195%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 75%;
        bottom: 0;
        left: 100%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -60%;
        left: 1120%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 90%;
        height: 50%;
        margin-top: -60%;
        margin-left: -5%;
    }
    .play-button {
        display: none;
      
    }


    .dooh-section {
        margin-top: -50%;
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 600px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 100%;
        margin-left: -30%;
        width: 60%;
        padding: 10%;
    }

    .video-container {
        width: 550px;
        height: 500px;
        margin-top: -30px;
        margin-left: 90px;
    }

    .home-video {
        width: 85%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }
    .play-button {
        display: none;
      
    }

    .stats-container {
        margin-top: 230%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 3rem;
    }

    .stat-item p {
        font-size: 1.7rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        width: 90%;
        height: 90%;
        margin-left: 9%;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 20%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 90%;
        left: -90%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 195%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 75%;
        bottom: 0;
        left: 100%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -60%;
        left: 1130%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 100%;
        height: 40%;
        margin-top: -70%;
        margin-left: 1%;
    }

    .dooh-section {
        margin-top: -65%;
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 545px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 100%;
        margin-left: -30%;
        width: 60%;
        padding: 10%;
    }

    .video-container {
        width: 550px;
        height: 500px;
        margin-top: -30px;
        margin-left: 90px;
    }

    .home-video {
        width: 85%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 247%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 3rem;
    }

    .stat-item p {
        font-size: 1.7rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        width: 90%;
        height: 90%;
        margin-left: 9%;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 20%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 90%;
        left: -90%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 195%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 75%;
        bottom: 0;
        left: 100%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -60%;
        left: 1130%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 100%;
        height: 40%;
        margin-top: -70%;
        margin-left: 1%;
    }

    .dooh-section {
        margin-top: -65%;
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
    .play-button {
        display: none;
      
    }

}

@media (max-width: 490px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 125%;
        margin-left: -30%;
        width: 50%;
        padding: 10%;
    }

    .video-container {
        width: 400px;
        height: 400px;
        margin-top: -30px;
        margin-left: 170px;
    }

    .home-video {
        width: 79%;
        height: 90%;
    }

    .play-button {
        /* display: none; */
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 310%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.5rem;
    }

    .stat-item p {
        font-size: 1.5rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        display: none;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 40%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 95%;
        left: -50%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 145%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 85%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -50%;
        left: 1170%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 100%;
        height: 30%;
        margin-top: -100%;
        margin-left: 7%;
    }

    .dooh-section {
        margin-top: -95%;
    }
    .play-button {
        display: none;
      
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 470px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 130%;
        margin-left: -30%;
        width: 50%;
        padding: 10%;
    }

    .video-container {
        width: 400px;
        height: 400px;
        margin-top: -70px;
        margin-left: 170px;
    }

    .home-video {
        width: 79%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 333%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.5rem;
    }

    .stat-item p {
        font-size: 1.5rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        display: none;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 15%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 95%;
        left: -83%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 115%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 85%;
        bottom: 0;
        left: 17%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -50%;
        left: 1135%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 130%;
        height: 30%;
        margin-top: -115%;
        margin-left: 7%;
    }

    .dooh-section {
        margin-top: -110%;
    }
    .play-button {
        display: none;
      
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 440px) {
    .home-wrapper{
        margin-top: -100px;
    }
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: 15%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 130%;
        margin-left: -30%;
        width: 50%;
        padding: 10%;
    }

    .video-container {
        width: 400px;
        height: 400px;
        margin-top: -70px;
        margin-left: 170px;
    }

    .home-video {
        width: 79%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 347%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.5rem;
    }

    .stat-item p {
        font-size: 1.5rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        display: none;
    }

    .car-image {
        display: none;
    }

    .card:nth-child(1) {
        top: -50%;
        left: 15%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 95%;
        left: -83%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 5%;
        left: 115%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 85%;
        bottom: 0;
        left: 17%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -50%;
        left: 1135%;
        transform: translate(-610%, 440%);
    }

    .orangecar-image {
        width: 130%;
        height: 30%;
        margin-top: -115%;
        margin-left: 7%;
    }

    .dooh-section {
        margin-top: -110%;
    }

    .curve-image {
        display: none;
    }
    .play-button {
        display: none;
      
    }


    .polo {
        display: none;
    }
}

@media (max-width: 395px) {
.home-image-container{
    position: relative;
    transform: translateY(100px);
    margin-bottom: 600px;
}
    .home-heading {
        display: flex;
        justify-content: flex-start;
        width: 140%;
        font-size: 18px;
        margin-left: -30px;
    }

    .home-subheading {
        width: 140%;
        font-size: 15px;
        margin-left: -30px;
    }
    .home-paragraph{
        width: 150%;
        margin-left: -40px;
    }

    /* .home-wrapper{
        margin-bottom: 50px;
    } */

    .home-content {
        flex-direction: column;
        padding-left: 330px;
        margin-top: -65%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 145%;
        margin-left: -30%;
        width: 47%;
        padding: 13%;
    }

    .video-container {
        width: 300px;
        height: 300px;
        margin-top: -120px;
        margin-bottom: 10px;
        margin-left: 200px;
    }

    .home-video {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 60%;
        margin-left: -20px;

    }

    .play-button {
        display: none;
      
    }

    .stats-container {
        margin-top: 275%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.2rem;
    }

    .stat-item p {
        font-size: 1.1rem;
        
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: -10px;

    }
    .home-content,h2{
font-size: 18px;
    }

    .home-content,.bullet-points{
        font-size: 18px;
    }

    .circle-image {
        display: flex;
        width: 70%;
        transform: translateY(-60px);
        transform: translateX(-45px);

    }

    .car-image {
        display: flex;
        margin-left: -70px;
        transform: translateX(-140px);
    }

     .card{
        width: 200%;
        height: 100%;
        /* margin-left: -20px; */
        margin-top: 450px;
    
    } 

    .card:nth-child(1) {
        top: -30%;
        left: 10%;
        transform: translate(-51%, -100%);
    }

    .card:nth-child(2) {
        top: 155%;
        left: -60%;
        transform: translate(-17%, -50%);
    }

    .card:nth-child(3) {
        top: 37%;
        left: 85%;
        transform: translate(-89%, -50%);
    }

    .card:nth-child(4) {
        top: 125%;
        bottom: 0;
        left: 10%;
        transform: translate(-52%, 100%);
    }

    .card:nth-child(5) {
        top: 125%;
        bottom: 0;
        left: 10%;
        transform: translate(-52%, 220%);
    }
.car-section{
    width: 110%;
    margin-top: 70%;
    margin-left: -7%;
    margin-bottom: -110%;
}
    /* .orangecar-image {
        width: 100%;
        height: 30%;
        margin-top: -20%;
        margin-left: 7%;
    } */

    /* .dooh-section {
        margin-top: -60%;

    }
.dooh-heading{
    font-size: 25px;
    margin-left: -15px;
}

.dooh-section p {
    display: flex;
    width: 120%;
    justify-content: center;
    text-align: justify;
    font-size: 16px;
    margin-left: -15px;

}
.dooh-section li{
    width: 120%;
    font-size: 16px;
    margin-left: -25px;
}

    .curve-image {
        display: none;
    } */
.circle-container{
    margin-top: -70px;
    margin-bottom: 450px;
}
    .polo {
        display: none;
    }
  
}

@media (max-width: 370px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 370px;
        margin-top: -35%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 145%;
        margin-left: -30%;
        width: 47%;
        padding: 13%;
    }

    .video-container {
        width: 300px;
        height: 300px;
        margin-top: -100px;
        margin-left: 200px;
    }

    .home-video {
        width: 80%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 330%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.2rem;
    }

    .stat-item p {
        font-size: 1.1rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        display: none;
    }

    .car-image {
        display: none;
    }

    .card {
        width: 150%;
        height: 100%;
    }

    .card:nth-child(1) {
        top: -30%;
        left: -10%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: 155%;
        left: -84%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: 37%;
        left: 67%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: 125%;
        bottom: 0;
        left: -10%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -90%;
        left: 830%;
        transform: translate(-610%, 440%);
    }
    .play-button {
        display: none;
      
    }

    .orangecar-image {
        width: 130%;
        height: 30%;
        margin-top: -10%;
        margin-left: 7%;
    }

    .dooh-section {
        margin-top: -60%;
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}

@media (max-width: 360px) {
    .home-heading {
        font-size: 18px;
    }

    .home-subheading {
        font-size: 24px;
    }

    .home-content {
        flex-direction: column;
        padding-left: 355px;
        margin-top: -35%;
        margin-right: 20px;
    }

    .home-left-content {
        margin-top: 145%;
        margin-left: -30%;
        width: 47%;
        padding: 13%;
    }

    .video-container {
        width: 300px;
        height: 300px;
        margin-top: -100px;
        margin-left: 200px;
    }

    .home-video {
        width: 80%;
        height: 90%;
    }

    .play-button {
        margin-top: -3%;
        margin-left: -7%;
        cursor: pointer;
    }

    .stats-container {
        margin-top: 377%;
        flex-direction: column;
        gap: 17px;
    }

    .stat-item h1 {
        font-size: 2.2rem;
    }

    .stat-item p {
        font-size: 1.1rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 150%;
        margin-left: 3%;
    }

    .circle-image {
        display: none;
    }

    .car-image {
        display: none;
    }

    .circle-container{
        margin-bottom: 50%;
    }

    .card {
        width: 150%;
        height: 100%;
    }

    .card:nth-child(1) {
        top: -280%;
        left: -10%;
        transform: translate(-50%, -100%);
    }

    .card:nth-child(2) {
        top: -65%;
        left: -84%;
        transform: translate(0, -50%);
    }

    .card:nth-child(3) {
        top: -200%;
        left: 67%;
        transform: translate(-100%, -50%);
    }

    .card:nth-child(4) {
        top: -81%;
        bottom: 0;
        left: -10%;
        transform: translate(-50%, 100%);
    }

    .card:nth-child(5) {
        top: -287%;
        left: 830%;
        transform: translate(-610%, 440%);
    }

    .play-button {
        display: none;

    }

    .orangecar-image {
        width: 130%;
        height: 30%;
        margin-top: -10%;
        margin-left: 7%;
    }

    .dooh-section {
        margin-top: -60%;
    }

    .curve-image {
        display: none;
    }

    .polo {
        display: none;
    }
}