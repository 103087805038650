.about-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 300px;
}

.about-image-container {
    position: relative;
    transform: translateY(-230px);
    margin-bottom: 400px;
}

.about-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.about-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 30px;
    text-align: center;
    z-index: 5;
}

.transform-section {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 18%;
    /* Adjust this to control vertical placement */
    left: 50%;
    transform: translate(-50%, -50%);
    /* Center it horizontally */
    padding: 25px;
    /* Optional padding */
    z-index: 5;
    /* Make sure it's above the image */
    width: 90%;
    margin-top: 70px;
}

.transform-left {
    flex: 1;
    /* Take full width available */
    margin-right: 20px;
    margin-top: 50px;
}

.transform-left-heading {
    font-size: 25px;
    margin-bottom: 20px;
}

.transform-left-paragraph {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.transform-right {
    flex: 1;
    /* Take full width available */
    display: flex;
    /* Use flexbox to center the image */
    justify-content: center;
    /* Center the image horizontally */
}

.transform-image {
    width: 100%;
    height: 70%;
    margin-top: 165px;
}

.innovative-section {
    display: flex;
    justify-content: space-around;
    padding: 80px;
    margin-bottom: 50px;
    transform: translateY(-270px);
    flex-direction: column;
}

.innovative-left {
    width: 50%;
    flex: 1;
    margin-left: -10px;
    transform: translateY(-190px);
    display: flex;
    justify-content: center;
}

.innovative-car {
    flex: 1;
    width: 49%;
    height: 50vh;
    object-fit: contain;
    margin-top: -13%;
    margin-left: -1%;
}

.innovative-left-heading {
    font-size: 25px;
    margin-bottom: 15px;
}

.innovative-pointers {
    list-style-type: disc;
    /* Makes the list items disc-shaped */
    padding-left: 20px;
    /* Spacing for the list */
    font-size: 18px;
        line-height: 1.5;
}
/* 
@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
} */

.innovative-right {
    flex: 1;
    justify-content: center;
    display: flex;
    gap: 40px;
    transform: translateY(80px);
    margin-top: 70px;
}

.Innovative-card {
    display: flex;
    align-items: center;
    /* Stretch the items to fill the card height */
    /* border: 1px solid #000; */
    /* Add border for visibility */
    padding: 0;
    /* Remove padding to allow image to fill the card */
    color: #000;
    /* Ensure text color is black */
    background-color: transparent;
    /* Ensure background is white */
    width: 50%;
    height: 200px;
}

.card-left {
    margin-top: 30px;
    margin-right: -550px;
    /* animation: slideInLeft 1.5s forwards;
    animation-delay: 1s; */
}

.card-right {
    margin-top: -350px;
    margin-right: -550px;
    /* animation: slideInRight 1.5s forwards;
    animation-delay: 1s; */
}

.innovative-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;   /* Allow content to fill the remaining space */
}

.Innovative-card-image {
    background-color: #ffa500;
    width: 25%;
    /* Set a fixed width for images */
    height: 95%;
    /* Maintain aspect ratio */
    margin-right: 15px;
    /* Space between image and text */
    object-fit: contain;
    border-radius: 24px;

}
.Innovative-card-image1 {
  background-color: #ffa500;
  width: 25%;
  /* Set a fixed width for images */
  height: 95%;
  /* Maintain aspect ratio */
  margin-right: 15px;
  /* Space between image and text */
  object-fit: contain;
  border-radius: 24px;

}


.Innovative-card-heading {
    font-size: 25px;
    margin: 20px 0 10px 0;
    /* Space below the heading */
}

.Innovative-card-paragraph {
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
}

.faq-section {
  margin: 0;
    display: flex;
    justify-content: space-around;
    padding: 0 90px;
    box-sizing: border-box;
    transform: translateY(-390px);
    margin-left: 120px;
}

.faq-left-section {
    flex: 1;
    width: 100%;
    margin-left: -74px;
}

.faq-right-section {
    /* flex: 2; */
    display: flex;
    flex-direction: column;
    /* margin-left: 150px; */
    /* width: calc(100% - 100px); */
    width: 50%;
}

.faq-subheading {
    color: #000000;
    font-size: 25px;
    margin-top: 5px;
    margin-left: -70px;
}

/* .faq-description {
    color: #828282;
    font-size: 16px;
    margin-top: 20px;
    width: 350px;
} */

.faq-item {
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #000;
    width: 100%;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.faq-answer {
    padding: 10px;
    border-left: 3px solid #000;
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
    color: #000;
}

.faq-icon {
    font-size: 20px;
    color: lightgrey;
}

.hazy-image{
    width: 100%;
    height: auto;
    object-fit: fill;
    margin-bottom: -304px;
}

.faq-answer-list {
    list-style-type: none;  /* Remove default bullets */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust the space between bullets */
    padding: 0;
  }
  
  .faq-answer-list li::before {
    content: "• ";  /* Custom bullet symbol */
  }
  
  .faq-answer-list li {
    margin: 0;  /* Ensure no vertical spacing */
    white-space: nowrap; /* Prevent wrapping for long text */
  }
  

/* Logo Carousel Section */
.logo-carousel-section {
    padding: 40px 0;
    background: linear-gradient(to right, #ffa500, #00bee079);
    text-align: center;
    margin-top: -200px;
    margin-bottom: -28px;
  }
  
  .logo-carousel-heading {
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Slick Slider Container */
  .slick-slider {
    width: 85%;
    margin: 0 auto;
  }
  
 
  
  .slick-track {
    display: flex;
  }
  

  
  .logo-image {
    max-width: 150px;
    max-height: 100px;
    transition: transform 0.3s ease;
    object-fit: contain;
  }
  
  /* Add hover effect */
  .logo-image:hover {
    transform: scale(1.1);
  }
  
  /* Style the navigation arrows */
  .slick-prev,
  .slick-next {
    display: none !important;
    font-size: 24px;
    color: #333;
    z-index: 1;
    top: 50%; /* Center the arrows vertically */
    transform: translateY(-50%);
  }
  
  .slick-prev {
    display: none;
    left: -40px; /* Position the left arrow */
  }
  
  .slick-next {
    right: -40px; /* Position the right arrow */
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #007bff;
  }

     @media only screen and (max-width: 1550px){
        .vision-card {
            margin-left: 12%;
          }

          .mission-card{
            margin-left: 12%;
          }
     }


  @media only screen and (max-width: 1200px) {
    .about-heading{
        top: 85%;
    }

    .transform-section{
        top: 13%;
        width: 87%;
    }

    .innovative-left{
        margin-top: -1%;
    }

    .vision-card{
        margin-top: 1%;
    }

    .mission-card{
        margin-bottom: 70%;
    }

    .innovative-card{
        height: auto;
    }

    .faq-section{
        margin-top: -50%;
    }
  }

  @media only screen and (max-width: 1114px) {
      .about-heading {
          top: 85%;
      }

      .transform-section {
          top: 17%;
          width: 87%;
      }

      .innovative-left {
          margin-top: -1%;
      }

      .vision-card{
        margin-top: 17%;
      }

      .innovative-card {
          height: auto;
      }
  }

  @media only screen and (max-width: 992px) {
      .about-heading {
          top: 110%;
      }

      .transform-section {
        flex-direction: column;
          top: 20%;
          width: 90%;
          margin: 0 auto;
          text-align: start;
      }

      .transform-image{
        width: 90%;
        height: 50%;
        margin-top: 5%;
      }

      .vision-card{
        margin-bottom: -330%;
        margin-left: 7%;
      }

      .mission-card{
        margin-bottom: -90%;
        margin-left: 7%;
      }

      .innovative-car{
        width: 90%;
        height: 10%;
        max-height: 30%;
        margin-left: 5%;
      }

      .innovative-left {
        flex-direction: column;
          margin-top: 97%;
          margin-left: 50px;
          text-align: start;
          width: 100%;
      }

           .innovative-right {
               flex-direction: column;
               /* Stack cards vertically */
               align-items: stretch;
               /* Stretch cards to full width */
               width: 100%;
           }
       
         .innovative-card {
             width: 100%;
             /* Reduced width for cards */
             height: 170px;
             /* Further reduced height for uniformity */
             display: flex;
             /* Use flex to center content */
             align-items: center;
             /* Center items vertically */
             justify-content: center;
             /* Center items horizontally */
             margin-bottom: 10px;
             /* Space between cards */
             border: 1px solid #000;
             /* Border for visibility */
             overflow: hidden;
         }
    
         .Innovative-card-content {
             flex: 1;
             text-align: center;
         }
    
         .Innovative-card-image {
             width: 30%;
             height: 500px;
             max-height: 80%;
             object-fit: contain;
             margin-right: 5px;
         }

         .Innovative-card-heading,
         .Innovative-card-paragraph{
            text-align: start;
            margin-left: 5px;
         }

         .Innovative-card-paragraph{
            font-size: 20px;
            width: 600px;
         }

         .faq-section{
            margin-top: 45%;
            flex-direction: column;
         }

         .faq-subheading{
            font-size: 27px;
         }

         .faq-right-section{
            margin-top: 30px;
            width: auto;
         }
  }

  @media only screen and (max-width: 900px) {
      .about-heading {
          top: 110%;
      }

      .transform-section {
          flex-direction: column;
          top: 17%;
          width: 90%;
          margin: 0 auto;
          text-align: start;
      }

      .innovative-left {
          margin-top: 700px;
          margin-left: 50px;
          text-align: start;
      }

      .innovative-right {
          flex-direction: column;
          /* Stack cards vertically */
          align-items: stretch;
          /* Stretch cards to full width */
          width: 85%;
      }

      .innovative-car{
        margin-top: -20%;
      }

      .vision-card{
        margin-top: 70%;
      }

      .mission-card{
        margin-top: -20%;
      }

      .innovative-card {
          width: 100%;
          /* Reduced width for cards */
          height: 150px;
          /* Further reduced height for uniformity */
          display: flex;
          /* Use flex to center content */
          align-items: center;
          /* Center items vertically */
          justify-content: center;
          /* Center items horizontally */
          margin-bottom: 10px;
          /* Space between cards */
          border: 1px solid #000;
          /* Border for visibility */
          overflow: hidden;
      }

      .Innovative-card-content {
          flex: 1;
          text-align: center;
      }

          .Innovative-card-image {
              width: 30%;
              height: 500px;
              max-height: 80%;
              object-fit: contain;
              margin-right: 5px;
          }

      .Innovative-card-heading,
      .Innovative-card-paragraph {
          text-align: start;
          margin-left: 5px;
      }

      .faq-section {
        margin-top: 55%;
          flex-direction: column;
      }

      .faq-subheading{
        font-size: 27px;
      }

      .faq-right-section {
          margin-top: 30px;
          width: auto;
      }

          .hazy-image {
              height: 50vh;
            object-fit: fill;
          }
  }

  @media only screen and (max-width: 850px) {
      .about-heading {
          top: 120%;
      }

      .transform-section {
          top: 18%;
          flex-direction: column;
          text-align: start;
          width: 90%;
      }

      .innovative-left {
          margin-top: 730px;
          margin-left: 40px;
      }

      .vision-card{
        margin-top: 115%;
      }

      .mission-card{
        margin-top: -10%;
      }

      .innovative-right {
          width: 85%;
      }

      .innovative-card {
          width: 100%;
          height: 20vh;
          margin-bottom: 10px;
      }

      .Innovative-card-image{
      object-fit: contain;
      height: 35vh;
      }

      .Innovative-card-heading {
          font-size: 24px;
          /* Reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 19px;
        width: 550px;
      }

      .faq-section {
        margin-top: 57%;
          flex-direction: column;
      }

      .faq-subheading {
          font-size: 26px;
      }

      .faq-right-section {
          margin-top: 30px;
          width: auto;
      }

          .hazy-image {
              height: 50vh;
            object-fit: fill;
          }
  }

  @media only screen and (max-width: 800px){
    .vision-card{
        margin-top: 140%;
    }
  }

  @media only screen and (max-width: 750px) {
      .about-heading {
          top: 145%;
      }

      .transform-section {
        top: 20%;
          margin: 0 20px;
      }

      .innovative-left {
        margin-top: 800px;
          margin-left: 50px;
          width: 100%;
      }

      .vision-card{
        margin-top: 195%;
      }

          .innovative-card {
              width: 100%;
              height: 20vh;
              margin-bottom: 10px;
          }

          .Innovative-card-image{
          object-fit: contain;
          height: 35vh;
          }

      .Innovative-card-heading {
          font-size: 25px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 19px;
          width: 500px;
      }

          .hazy-image {
              height: 50vh;
            object-fit: fill;
          }
  }

  @media only screen and (max-width: 700px){
    .vision-card{
        margin-top: 260%;
    }

    .Innovative-card-paragraph{
        width: 450px;
    }
  }

  @media only screen and (max-width: 670px) {
      .about-heading {
          top: 150%;
      }

          .transform-section {
              top: 17%;
              margin: 0 20px;
          }

          .innovative-left {
              margin-top: 700px;
              margin-left: 10px;
              width: 100%;
          }

          .vision-card{
            margin-top: 270%;
          }

          .mission-card{
            margin-top: 30%;
          }


      .innovative-card {
          height: 70vh;
          /* Further reduced height */
      }

      .Innovative-card-image{
      object-fit: contain;
      height: 60vh;
      }

      .Innovative-card-heading {
          font-size: 22px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 19px;
          width: 430px;
      }

          .hazy-image {
              height: 50vh;
            object-fit: fill;
          }
  }

  @media only screen and (max-width: 600px) {
      .about-heading {
          top: 170%;
      }

      .transform-section{
        top: 17%;
      }

      .innovative-left {
          margin-top: 630px;
          margin-left: 17px;
          width: 100%;
      }

      .innovative-car{
        margin-top: -30%;
      }

      .vision-card{
        margin-top: 250%;
      }

      .mission-card{
        margin-top: 40%;
      }


      .innovative-card {
          height: 70vh;
          /* Further reduced height */
      }

      .Innovative-card-image {
          object-fit: contain;
          height: 47vh;
      }

      .Innovative-card-heading {
          font-size: 22px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 19px;
          /* Further reduced font size */
      }

        .faq-answer-list {
            overflow: hidden;
            flex-direction: column;
            flex-wrap: wrap;
            font-size: 15px;
            padding: 10px 0;
            width: 100%;
            height: 70%;
            word-wrap: break-word;
            /* Allows long words to break and wrap to the next line */
            overflow-wrap: break-word;
            /* Same as above, adds support for older browsers */
            white-space: normal;
            /* Ensures the text wraps normally */
        }

      .hazy-image {
          height: 50vh;
          object-fit: fill;
      }
  }

  @media only screen and (max-width: 550px) {
      .about-heading {
          top: 190%;
      }

      .transform-section {
          padding: 27px;
      }

          .innovative-left {
              margin-top: 500px;
              margin-left: 10px;
              width: 100%;
          }

      .innovative-card {
          height: 50vh;
          /* Further reduced height */
      }

      .Innovative-card-image{
      object-fit: contain;
      height: 40vh;
      }

      .Innovative-card-heading {
          font-size: 19px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 17px;
          /* Further reduced font size */
      }

          .hazy-image {
              height: 50vh;
            object-fit: fill;
          }
  }

  @media only screen and (max-width: 500px) {
      .about-heading {
          top: 230%;
      }

      .transform-section {
          padding: 27px;
      }

      .transform-left-heading{
        width: 130%;
      }

      .innovative-left {
          margin-top: 700px;
          margin-left: 10px;
          width: 100%;
      }

      .innovative-card {
          height: 50vh;
          /* Further reduced height */
      }

      .Innovative-card-image {
          object-fit: contain;
          height: 63vh;
      }

      .Innovative-card-heading {
          font-size: 21px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 17px;
          width: 200px;
          /* Further reduced font size */
      }

      .hazy-image {
          height: 30vh;
          object-fit: fill;
      }
  }

  @media only screen and (max-width: 450px) {
      .about-heading {
          top: 220%;
      }

           .transform-section {
            top: 23%;
            width: 100%;
               padding: 55px;
           }

           .transform-left-heading{
            font-size: 25px;
            margin-top: -350px;
           }

           .transform-left-paragraph{
            font-size: 23px;
           }
    
           .innovative-left {
               margin-top: -790px;
               margin-left: 5px;
               width: 100%;
           }

      .innovative-card {
          height: 70vh;
          width: 100%;
          /* Further reduced height */
      }

      .Innovative-card-image{
      object-fit: contain;
      height: 77vh;
      }

      .Innovative-card-heading {
          font-size: 20px;
      }

      .Innovative-card-paragraph {
          font-size: 19px;
      }

          .hazy-image {
              height: 30vh;
            object-fit: fill;
          }
          
      .transform-image{
        margin-bottom: 10px;
      }
  }


  @media only screen and (max-width: 380px) {
      .about-heading {
          top: 250%;
      }

      .transform-section {
          top: 22%;
          width: 100%;
          padding: 55px;
      }

      .transform-left-heading {
          font-size: 20px;
      }

      .transform-left-paragraph {
          font-size: 20px;
      }

          .innovative-left {
              margin-top: 990px;
              margin-left: 5px;
              width: 100%;
          }

      .innovative-card {
          height: 60px;
          /* Further reduced height */
      }

      .innovative-left-heading,
      .innovative-pointers{
        font-size: 20px;
      }

      .Innovative-card-image {
          object-fit: contain;
          height: 75vh;
      }

      .Innovative-card-heading {
          font-size: 17px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 15px;
          /* Further reduced font size */
      }

      .hazy-image{
        height: 30vh;
        object-fit: fill;
      }
  }

  @media only screen and (max-width: 370px) {
      .about-heading {
          top: 290%;
      }

      .transform-section {
          top: 22%;
          width: 100%;
          padding: 55px;
      }

      .transform-left-heading {
          font-size: 20px;
      }

      .transform-left-paragraph {
          font-size: 20px;
      }

      .innovative-left {
          margin-top: 990px;
          margin-left: 5px;
          width: 100%;
      }

      .innovative-card {
          height: 60px;
          /* Further reduced height */
      }

      .innovative-left-heading,
      .innovative-pointers {
          font-size: 20px;
      }

      .Innovative-card-image {
          object-fit: contain;
          height: 75vh;
      }

      .Innovative-card-heading {
          font-size: 17px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 15px;
          /* Further reduced font size */
      }

      .hazy-image {
          height: 30vh;
          object-fit: fill;
      }
  }

  @media only screen and (max-width: 360px) {
      .about-heading {
          top: 290%;
      }

      .transform-section {
          top: 22%;
          width: 100%;
          padding: 55px;
      }

      .transform-left-heading {
          font-size: 20px;
      }

      .transform-left-paragraph {
          font-size: 20px;
      }

      .innovative-left {
          margin-top: 1230px;
          margin-left: 5px;
          width: 100%;
      }

      .innovative-card {
          height: 60px;
          /* Further reduced height */
      }

      .innovative-left-heading,
      .innovative-pointers {
          font-size: 20px;
      }

      .Innovative-card-image {
          object-fit: contain;
          height: 75vh;
      }

      .Innovative-card-heading {
          font-size: 17px;
          /* Further reduced font size */
      }

      .Innovative-card-paragraph {
          font-size: 15px;
          /* Further reduced font size */
      }

      .hazy-image {
          height: 30vh;
          object-fit: fill;
      }
  }


  /* Media query for screens 820px and below */
@media (max-width: 820px) {
    .faq-right-section {
      width: 100%;
      padding: 0 10px;
    }
  
    .faq-item {
      padding: 10px 0;
    }
  
    .faq-question {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .faq-answer {
      margin-top: 10px;
    }
  
    .faq-answer-list {
      padding-left: 20px;
      font-size: 14px;
      word-wrap: break-word; /* Ensure long words break onto the next line */
    }
  
    .faq-section {
      display: flex;
      flex-direction: column;
    }
  
    .faq-left-section,
    .faq-right-section {
      width: 100%;
    }
  }
  

  /* Media query for screens 669px and below */
@media (max-width: 669px) {
    .faq-right-section {
      width: 100%;
      padding: 0 5px; /* Less padding for smaller screens */
    }
  
    .faq-item {
      padding: 8px 0; /* Reduce padding to fit smaller screens */
    }
  
    .faq-question {
      font-size: 14px; /* Smaller font for questions */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .faq-answer {
      margin-top: 8px;
    }
  
    .faq-answer-list {
      padding-left: 15px;
      font-size: 12px; /* Smaller font size for answers */
      word-wrap: break-word; /* Ensure text breaks properly */
    }
  
    .faq-section {
        margin-top: 80%;
      display: flex;
      flex-direction: column;
    }
  
    .faq-left-section,
    .faq-right-section {
      width: 100%;
    }
  
    .transform-left-heading,
    .innovative-left-heading {
      font-size: 20px; /* Adjust headings for smaller screens */
    }
  
    .transform-left-paragraph {
      font-size: 17px; /* Adjust paragraph font size for smaller screens */
    }
  }
  

  /* Media query for screens 600px and below */
@media (max-width: 600px) {
    .faq-right-section {
      width: 100%;
      padding: 0 5px;
    }
  
    .faq-item {
      padding: 5px 0; /* Reduce padding even more */
    }
  
    .faq-question {
      font-size: 13px; /* Smaller font size for questions */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .faq-answer {
      margin-top: 5px;
    }
  
    .faq-answer-list {
      padding-left: 10px; /* Smaller indent */
      font-size: 11px; /* Smaller font size for answers */
      word-wrap: break-word; /* Ensure text wraps to the next line */
    }
  
    .faq-section {
        margin-top: 80%;
      display: flex;
      flex-direction: column;
    }
  
    .faq-left-section,
    .faq-right-section {
      width: 100%;
    }
  
    /* Adjust headings and paragraphs for small screens */
    .transform-left-heading,
    .innovative-left-heading {
      font-size: 20px;
    }

    .transform-left-paragraph {
      font-size: 16px; /* Reduce paragraph font size */
    }
  
    .Innovative-card-heading {
      font-size: 20px; /* Smaller font for card headings */
    }
  
    .Innovative-card-paragraph {
      font-size: 17px;
      width: 350px; /* Smaller font for card paragraphs */
    }
  
    .faq-text {
      font-size: 13px; /* Adjust FAQ text size */
    }
  
    /* Make images responsive */
    img {
      max-width: 100%;
      height: auto;
    }
  }
  

  /* Media query for screens 575px and below */
@media (max-width: 575px) {
    .faq-right-section {
      width: 100%;
      padding: 0 10px; /* Increase padding for better readability */
    }
  
    .faq-item {
      padding: 8px 0; /* Slightly increase padding for better separation */
    }
  
    .faq-question {
      font-size: 14px; /* Keep questions readable */
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .faq-answer {
      margin-top: 5px;
      display: block;
      font-size: 13px; /* Slightly larger font for better visibility */
      line-height: 1.5; /* Add line-height to increase readability */
      opacity: 0.9; /* Make the answer slightly more visible */
    }
  
    .faq-answer-list {
      padding-left: 15px; /* Ensure consistent spacing */
      font-size: 12px; /* Keep the font size readable */
      word-wrap: break-word; /* Ensure long words break to the next line */
    }
  
    .faq-section {
      display: flex;
      flex-direction: column;
    }
  
    .faq-left-section,
    .faq-right-section {
      width: 100%;
    }
  
    /* Adjust headings and paragraphs for small screens */
    .transform-left-heading,
    .innovative-left-heading {
      font-size: 19px; /* Further reduce heading sizes */
    }
  
    .transform-left-paragraph {
      font-size: 15px; /* Adjust paragraph font size */
      line-height: 1.6; /* Add line-height for readability */
    }
  
    .Innovative-card-heading {
      font-size: 18px; /* Slightly smaller heading for cards */
    }
  
    .Innovative-card-paragraph {
      font-size: 15px; /* Smaller font for paragraphs */
    }
  
    /* Responsive images for smaller screens */
    img {
      max-width: 100%;
      height: auto;
    }

    .vision-card{
        margin-top: 290%;
  }
} 
  

  @media (max-width: 560px) {
    .faq-right-section {
        width: 100%;
        padding: 0 10px; /* Increase padding for better readability */
      }
    
      .faq-item {
        padding: 8px 0; /* Slightly increase padding for better separation */
      }
    
      .faq-question {
        font-size: 14px; /* Keep questions readable */
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .faq-answer {
        margin-top: 5px;
        display: block;
        font-size: 5px; /* Slightly larger font for better visibility */
        line-height: 1.5; /* Add line-height to increase readability */
        opacity: 0.9; /* Make the answer slightly more visible */
      }
    
      .faq-answer-list {
        padding-left: 0; /* Ensure consistent spacing */
        font-size: 5px; /* Keep the font size readable */
        word-wrap: break-word; /* Ensure long words break to the next line */
      }
    
      .faq-section {
        display: flex;
        flex-direction: column;
      }
    
      .faq-left-section,
      .faq-right-section {
        width: 100%;
      }

      .innovative-left{
        margin-top: 130%;
      }

      .vision-card{
        margin-top: 330%;
      }
    
      /* Adjust headings and paragraphs for small screens */
      .transform-left-heading,
      .innovative-left-heading {
        font-size: 20px; /* Further reduce heading sizes */
      }
    
      .transform-left-paragraph {
        font-size: 15px; /* Adjust paragraph font size */
        line-height: 1.6; /* Add line-height for readability */
      }
    
      .Innovative-card-heading {
        font-size: 17px; /* Slightly smaller heading for cards */
      }
    
      .Innovative-card-paragraph {
        font-size: 14px; /* Smaller font for paragraphs */
      }
    
      /* Responsive images for smaller screens */
      img {
        max-width: 100%;
        height: auto;
      }
  }

  @media (max-width: 480px){
    .innovative-left{
        margin-top: 70%;
    }

    .vision-card{
        margin-top: 500%;
    }

    .mission-card{
        margin-top: 50%;
    }

    .Innovative-card-paragraph{
        width: 230px;
    }
  }

  @media (max-width: 420px) {
    .about-image-container{
      position: relative;
      transform: translateY(-10px);
      margin-bottom: 600px;
  }
    .about-heading{
      margin-top: -180px;
    }
      .innovative-left {
          margin-top: -50%;
      }

      .vision-card {
          margin-top: 570%;
          padding: 0 1 0 0;
      }

      .mission-card {
          margin-top: 50%;
          padding: 0 1 0 0;
      }

      .innovative-car{
        transform: translateY(-40px);
        width: 100%;
      }
.faq-subheading{
  width: 100%;
  margin-top: 40px;
}

      .faq-right-section {
        width: 120%;
        margin-left: -140px;
        padding: 0 10px; /* Increase padding for better readability */
      }
    
    
      .faq-question {
        font-size: 18px;
  
      }
    
      .faq-answer {
        margin-top: 5px;
        display: block;
        font-size: 15px; /* Slightly larger font for better visibility */
        line-height: 1.5; /* Add line-height to increase readability */
        opacity: 0.9; /* Make the answer slightly more visible */
      }
    
      .faq-answer-list {
        padding-left: 0; /* Ensure consistent spacing */
        font-size: 8px; /* Keep the font size readable */
        word-wrap: break-word; /* Ensure long words break to the next line */
      }
    
      .faq-section {
        display: flex;
        flex-direction: column;
        width: 120%;
      }
    
      .faq-left-section,
      .faq-right-section {
        width: 100%;
      }
      .innovative-left-heading{
        font-size: 20px;
      }

      .Innovative-card-paragraph {
          width: 230px;
      }
      .Innovative-card-image {
        margin-left: -45px;
        width: 80%;
        height: 300px;
      }

      .Innovative-card-image1 {
        margin-left: -45px;
        width: 80%;
        height: 180px;
      }
.innovative-section{
  margin-bottom: -10px;
}
.vision-card{
  transform:translateY(-310px);
  width: 50%;
}
.mission-card{
  transform:translateY(-70px);
  width: 50%;
  margin-bottom: -250px;
}
.innovative-car{
  transform: translateY(-90px);
}
.transform-section{
  margin-top: 45px;
}

  }

  @media (max-width: 370px) {

  
    .transform-section{
        margin-top: 20%;
    }
      .innovative-left{
        margin-top:  -50px;
      }

      .vision-card{
        margin-top: 700%;
      }

      .mission-card{
        margin-top: 50%;
      }
      .faq-right-section {
        width: 100%;
        padding: 0 10px; /* Increase padding for better readability */
      }
    
      .faq-item {
        padding: 8px 0; /* Slightly increase padding for better separation */
      }
    
      .faq-question {
        font-size: 14px; /* Keep questions readable */
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .faq-answer {
        margin-top: 5px;
        display: block;
        font-size: 5px; /* Slightly larger font for better visibility */
        line-height: 1.5; /* Add line-height to increase readability */
        opacity: 0.9; /* Make the answer slightly more visible */
      }
    
      .faq-answer-list {
        padding-left: 0; /* Ensure consistent spacing */
        font-size: 5px; /* Keep the font size readable */
        word-wrap: break-word; /* Ensure long words break to the next line */
      }
    
      .faq-section {
        display: flex;
        flex-direction: column;
      }
    
      .faq-left-section,
      .faq-right-section {
        width: 100%;
      }
  }

    @media (max-width: 360px) {

      .faq-answer {
          margin-top: 5px;
          display: block;
          font-size: 3px;
          /* Slightly larger font for better visibility */
          line-height: 1.5;
          /* Add line-height to increase readability */
          opacity: 0.9;
          /* Make the answer slightly more visible */
        }
      
        .faq-answer-list {
          padding-left: 0;
          /* Ensure consistent spacing */
          font-size: 3px;
          /* Keep the font size readable */
          word-wrap: break-word;
          /* Ensure long words break to the next line */
        }
  
      .transform-section {
        margin-top: 37%;
      }
  
      .innovative-left {
        margin-top: -300px;
      }
  
      .vision-card {
        margin-top: 810%;
      }
  
      .mission-card {
        margin-top: 50%;
      }
      .faq-right-section {
        width: 100%;
        padding: 0 10px; /* Increase padding for better readability */
      }
    
      .faq-item {
        padding: 8px 0; /* Slightly increase padding for better separation */
      }
    
      .faq-question {
        font-size: 14px; /* Keep questions readable */
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    
      .faq-answer {
        margin-top: 5px;
        display: block;
        font-size: 5px; /* Slightly larger font for better visibility */
        line-height: 1.5; /* Add line-height to increase readability */
        opacity: 0.9; /* Make the answer slightly more visible */
      }
    
      .faq-answer-list {
        padding-left: 0; /* Ensure consistent spacing */
        font-size: 5px; /* Keep the font size readable */
        word-wrap: break-word; /* Ensure long words break to the next line */
      }
    
      .faq-section {
        display: flex;
        flex-direction: column;
      }
    
      .faq-left-section,
      .faq-right-section {
        width: 100%;
      }
    }

    @media (max-width: 350px) {
      .faq-answer {
        margin-top: 5px;
        display: block;
        font-size: 3px;
        /* Slightly larger font for better visibility */
        line-height: 1.5;
        /* Add line-height to increase readability */
        opacity: 0.9;
        /* Make the answer slightly more visible */
      }
  
      .faq-answer-list {
        padding-left: 0;
        /* Ensure consistent spacing */
        font-size: 3px;
        /* Keep the font size readable */
        word-wrap: break-word;
        /* Ensure long words break to the next line */
      }
    }

        @media (max-width: 340px){
          .faq-answer {
              margin-top: 5px;
              display: block;
              font-size: 3px;
              /* Slightly larger font for better visibility */
              line-height: 1.5;
              /* Add line-height to increase readability */
              opacity: 0.9;
              /* Make the answer slightly more visible */
            }
          
            .faq-answer-list {
              padding-left: 0;
              /* Ensure consistent spacing */
              font-size: 3px;
              /* Keep the font size readable */
              word-wrap: break-word;
              /* Ensure long words break to the next line */
            }
        }
  

                @media (max-width: 330px) {
                  .faq-answer {
                    margin-top: 5px;
                    display: block;
                    font-size: 3px;
                    /* Slightly larger font for better visibility */
                    line-height: 1.5;
                    /* Add line-height to increase readability */
                    opacity: 0.9;
                    /* Make the answer slightly more visible */
                  }
        
                  .faq-answer-list {
                    padding-left: 0;
                    /* Ensure consistent spacing */
                    font-size: 3px;
                    /* Keep the font size readable */
                    word-wrap: break-word;
                    /* Ensure long words break to the next line */
                  }
                }