@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');


body {
  font-family: 'Figtree', sans-serif;
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.mr-2 {
  margin-right: 2rem;
}
.cookie-box {
  position: fixed;
  left: 1rem;
  bottom: 0;
  width: calc(100% - 2rem);
  z-index: 100;
}
.cookie-box__section {
  background-color: white;
  box-shadow: 0 0 1px 1px black;
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.cookie-box__content {
  margin-bottom: 1rem;
  align-items: center;
}
.cookie-box__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  background-color: #ffa500;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 1rem;
  white-space: nowrap;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #d0701c;
}

@media only screen and (min-width: 768px){
  .cookie-box__section {
    display: flex;
  }
  .cookie-box__content {
    padding-right: 1rem;
    margin-bottom: 0;
    align-items: center;
  }
}