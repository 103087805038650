.dashboard-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
}

.dashboard-image-container {
    position: relative;
    transform: translateY(-230px);
    /* margin-bottom: 400px; */
}

.dashboard-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.dashboard-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 60px;
    text-align: center;
    z-index: 5;
}