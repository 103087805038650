.advertisers-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 160px;
}

.advertisers-image-container {
    position: relative;
    transform: translateY(-230px);
    margin-bottom: 400px;
}

.advertisers-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.advertisers-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 30px;
    text-align: center;
    z-index: 5;
}

.advertisers-img {
    position: relative;
    padding-bottom: 40px;
}

.advertisers-img .car-img {
    position: absolute;
    width: 60%;
    margin-left: 250px;
    margin-top: -670px;
}

.advertise-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 300px; */
    transform: translateY(-130px);
}

.para-descrip {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.img-descrip {
    flex: 1;
}

.advertise-img {
    width: 600px;
    height: 550px;
    padding-left: 80px;
    /* margin-bottom: 10px; */
}

.explain-descrip {
    flex: 2;
    padding-left: 50px;
    margin-bottom: 70px;
    margin-bottom: -5px;
}

.h3-heading {
    font-size: 25px;

    font-weight: 700;
    color: #000;
    margin-bottom: 2px;
}

.flex-ul li {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-left: 20px;
}

.explain-descrip p {
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 18px;
}

.content-play {
    background-color: #ffa500;
    transform: translateY(-100px);
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid white;
    margin-bottom: -261px;
}

.content-play h3 {
    font-size: 40px;
    color: white;
    font-weight: 700;
    text-align: center;

    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

/* .play-button1 {
    position: relative;
    cursor: pointer;
    margin-left: 35rem;
    margin-top: 20px;
} */

/* .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Dark overlay */
/* display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
} */
/* .video-player {
    width: 100%;
    max-width: 800px;
    position: relative;
} */

.close-button1 {
    position: absolute;
    top: 100px;
    right: 17rem;
    background: white;
    border: 1px solid white;
    color: rgb(17, 17, 17);
    font-size: 15px;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 1100;
}

.solutions {
    display: flex;
    justify-content: space-between;
    background-color: #ffa500;
    height: 250px;
    position: absolute;
    width: 100%;
    transform: translateY(-90px);
}

.flex1 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    margin-top: -5rem;

}

.flex1 .head-flex {
    font-size: 30px;
    padding-top: 80px;
    padding-left: 40px;
    color: #000;
}

.para-flex {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    width: 570px;
    height: 31px;
    padding-left: 40px;
    padding-top: 30px;
}

.flex2 {
    flex: 1;
    display: flex;
    padding-left: 380px;
}

/* .img-truck {
    padding-top: 40px;
    max-width: 500px;
    height: 240px;
    transform: translateX(-170px);
} */


@media only screen and (max-width: 1550px) {
    .advertisers-img {
        margin-bottom: 50px;
    }

    .advertise-img {
        width: 600px;
        height: 400px;
    }

    .play-button1 {
        margin-left: 700px;
    }
}

@media only screen and (max-width: 1350px) {
    .advertisers-img {
        margin-bottom: -50px;
    }

    .advertise-img {
        width: 600px;
        height: 470px;
    }

    .play-button1 {
        margin-left: 600px;
    }
}

@media only screen and (max-width: 1200px) {

    .advertisers-heading {
        top: 85%;
    }

    .advertisers-img {
        margin-top: 50px;
        margin-left: -10px;
    }

    .advertise-flex {
        margin-top: -5%;
        width: 100%;
    }

    .img-descrip {
        width: 90%;
    }

    .play-button1 {
        margin-left: 500px;
    }

    .img-truck {
        margin-left: -150px;
    }



}

@media only screen and (max-width: 1070px) {

    .advertisers-heading {
        top: 85%;
    }

    .advertisers-img {
        margin-top: 90px;
        margin-left: -30px;
    }

    .advertise-img {
        display: none;
    }

    .advertise-flex {
        flex-direction: column;
        top: 20%;
        width: 90%;
        margin: 0 -370px;
        text-align: start;
        margin-top: -70px;
    }

    .img-descrip {
        width: 190%;
    }

    .play-button1 {
        margin-left: 500px;
    }

    .img-truck {
        margin-left: -200px;
    }

}

@media only screen and (max-width: 992px) {
    .advertisers-heading {
        top: 100%;
    }

    .advertisers-img {
        margin-top: 50%;
        margin-left: -70px;
    }

    .advertise-flex {
        flex-direction: column;
        top: 18%;
        width: 90%;
        margin: 0 auto;
        text-align: start;
    }

    .advertise-img {
        display: none;
    }

    .img-descrip {
        flex-direction: column;
        margin-top: -11%;
        align-items: flex-start;
        width: 100%;
    }

    .explain-descrip {
        margin-top: 5px;
        margin-left: 25px;
        text-align: start;
    }

    .content-play h3 {
        text-align: center;
    }

    .play-button1 {
        margin-left: 40%;
    }

    .img-truck {
        margin-left: -270px;
    }
}

@media only screen and (max-width: 900px) {
    .advertisers-heading {
        top: 110%;
    }

    .advertisers-img {
        width: 130%;
        margin-top: 60%;
        margin-left: -130px;
    }

    .advertise-flex {
        flex-direction: column;
        top: 14%;
        width: 100%;
        margin: 0 20px;
        text-align: start;
    }

    .explain-descrip {
        margin-top: 5px;
        margin-left: 30px;
        text-align: start;
    }

    .img-descrip {
        flex-direction: column;
        /* Stack cards vertically */
        align-items: stretch;
        /* Stretch cards to full width */
        width: 85%;
    }

    .play-button1 {
        margin-left: 45%;
    }

    .img-truck {
        display: none;
    }

    .video-player {
        width: 90%;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 850px) {
    .advertisers-heading {
        top: 120%;
    }

    .advertisers-img {
        margin-top: 65%;
        margin-left: -140px;
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .img-descrip {
        margin-top: 10px;
        margin-left: -20px;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -100px;
    }
}

@media only screen and (max-width: 750px) {
    .advertisers-heading {
        top: 130%;
    }

    .advertisers-img {
        margin-top: 80%;
        margin-left: -150px;
    }

    .advertise-flex {
        top: 17%;
        margin: 0 20px;
    }

    .advertise-img {
        width: 100%;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -150px;
    }
}

@media only screen and (max-width: 650px) {
    .advertisers-heading {
        top: 160%;
    }

    .advertisers-img {
        margin-top: 100%;
        margin-left: -150px;
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        width: 120%;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -200px;
    }
}

@media only screen and (max-width: 550px) {
    .advertisers-heading {
        top: 190%;
    }

    .advertisers-img {
        margin-top: 125%;
        /* margin-left: -200px; */
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        width: 120%;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -270px;
    }

    .content-play h3 {
        text-align: center;
        width: 79%;
    }

    .solutions {
        text-align: flex-start;
    }

    .head-flex {
        width: 300%;
        margin-left: -20px;
    }

    .para-flex {
        margin-left: -20px;
        width: 270%;
    }

}

@media only screen and (max-width: 500px) {
    .advertisers-heading {
        top: 200%;
    }

    .advertisers-img {
        margin-top: 140%;
        /* margin-left: -200px; */
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        width: 120%;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -300px;
    }

    .content-play h3 {
        text-align: center;
        width: 77%;
    }

    .play-button1 {
        margin-left: 40%;
    }

    .solutions {
        text-align: flex-start;
    }

    .head-flex {
        width: 250%;
        margin-left: -20px;
    }

    .para-flex {
        margin-left: -20px;
        width: 240%;
    }

}

@media only screen and (max-width: 450px) {
    .advertisers-heading {
        top: 230%;
    }

    .advertisers-img {
        margin-top: 170%;
        margin-left: -160px;
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        width: 120%;
    }

    .content-play h3 {
        text-align: center;
        margin-right: 10px;
        width: 81%;
    }

    .explain-descrip {
        margin-left: 10px;
        margin-top: -300px;
    }

    .head-flex {
        width: 250%;
        margin-left: -33px;
    }

    .para-flex {
        margin-left: -33px;
        width: 230%;
        margin-top: -20px;
    }
}

@media only screen and (max-width: 400px) {
    .advertisers-heading {
        top: 250%;
    }

    .advertisers-img {
        margin-top: 200%;
        margin-left: -180px;
    }

    .advertise-flex {
        top: 19%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        width: 140%;
        height: 300px;
        margin-left: -50px;
    }

    .explain-descrip {
        /* margin-left: 40px; */
        margin-top: -330px;
    }

    .content-play h3 {
        text-align: center;
        margin-right: 10px;
        width: 88%;
    }

    .head-flex {
        width: 230%;
        margin-left: -25px;
    }

    .para-flex {
        margin-left: -25px;
        width: 190%;
        margin-top: -20px;
    }

}

@media only screen and (max-width: 395px) {

    .crazytag{
        display: flex;
    }

    .advertisers-image-container{
        position: relative;
        transform: translateY(-10px);
        margin-bottom: 600px;
    }

    .advertisers-heading {
        top: 105%;
    }

    .advertisers-img {
        margin-top: 175%;
        margin-left: -200px;
    }

    .advertise-flex {
        top: 14%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        margin-left: -50px;
        width: 150%;
        height: 250px;
    }

    .explain-descrip {
        margin-left: -20px;
        margin-top: -350px;
        font-size: 12px;
        width: 110%;
    }

    .head-flex {
        width: 170%;
        margin-top: -50px;
        margin-left: -20px;
    }

    .para-flex {
        margin-left: -20px;
        width: 190%;
        margin-top: -20px;
    }

    .content-play {
        margin-left: -1px;
        width: 100%;
        font-size:10px;
        margin-bottom: -231px;
    }

}


@media only screen and (max-width: 380px) {
    .advertisers-heading {
        top: 270%;
    }

    .advertisers-img {
        margin-top: 200%;
        margin-left: -160px;
    }

    .advertise-flex {
        top: 14%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        margin-left: -50px;
        width: 150%;
        height: 250px;
    }

    .explain-descrip {
        /* margin-left: 40px; */
        margin-top: -350px;
    }

    .head-flex {
        width: 170%;
        margin-top: -50px;
        margin-left: -20px;
    }

    .para-flex {
        margin-left: -20px;
        width: 190%;
        margin-top: -20px;
    }
}

@media only screen and (max-width: 360px) {
    .advertisers-heading {
        top: 170%;
    }

    .advertisers-img {
        margin-top: 220%;
        margin-left: -190px;
    }

    .advertise-flex {
        top: 14%;
        margin: 0 20px;
        flex-direction: column;
        text-align: start;
        width: 90%;
    }

    .advertise-img {
        margin-left: -50px;
        width: 150%;
        height: 250px;
    }

    .explain-descrip {
        /* margin-left: 40px; */
        margin-top: -350px;
    }

    .head-flex {
        width: 170%;
        margin-top: -50px;
        margin-left: -35px;
    }

    .para-flex {
        margin-left: -35px;
        width: 190%;
        margin-top: -20px;
    }
}