.global-header {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    
}

.header-logo {
    width: 190px;
    height: 50px;
}

.header-nav {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.nav-link {
    margin: 0 20px;
    text-decoration: none;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    transition: color 0.3s;
}

.nav-link:hover {
    color: #ffa500;
}

.active-link {
    color: #ffa500;
}

.login-button {
    padding: 17px 90px;
    background-color: #ffa500;
    color: #fff;
    border: none;
    box-shadow: #333;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    border-radius: 24px;
}

.login-button:hover {
    background-color: #d0701c;
}

.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(47, 46, 46, 0.415);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;

}

.popup-content {
    background-color: #000;
    width: 750px;
    max-height: 90vh;
    /* overflow-y: scroll; */
    padding-top: 5rem;
    padding-left: 5rem;
    position: relative;
    margin-right: 1rem;
}

.close-popup {
    position: absolute;
    top: 10px;
    right: 14px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: white;

}

.Span-popup {
    border-bottom: 4px solid #ffa500;
}

.popup-heading {
    letter-spacing: 0.16px;
    margin-top: 1rem;
    font: 700 29px / 38px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    color: white;
}

.popup-text {
    color: #ffceab;
    font-family: Poppins, sans-serif;
    line-height: 30px;
    letter-spacing: 0.09px;
    margin-top: 1rem;
    margin-bottom: 20px;
}

.login-box {
    border-radius: 5px;
    background-color: #2a2e45;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
    border: 2px solid #ffa500;
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    flex-direction: column;
    width: 90%;
    margin-bottom: 40px;
}

.input-container {
    position: relative;
    width: 100%;
}

.input-container .login-input {
    margin-top: 2rem;
    margin-left: 20px;
    font-size: 16px;
    border: 1px solid #ffa500;
    background-color: #2a2e45;
    color: white;
    width: 92%;
    padding-top: 7px;
    padding-left: 35px;
    padding-bottom: 5px;
    outline: none;
}

.input-container .login-input:focus {
    border-color: #ffffff;
}

.icon1,
.icon2 {
    position: absolute;
    top: 72%;
    left: 24px;
    transform: translateY(-50%);
    font-size: 24px;
    color: #ffa500;
}

.sign-in-button {
    width: 92%;
    border-radius: 33px;
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
    margin-top: 28px;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    justify-content: center;
    padding: 26px 60px;
    flex-wrap: wrap;
    background-color: #ffa500;
    color: white;
    border: none;
    margin-left: 20px;
    margin-bottom: 10px;
    cursor: pointer;

}


.error-message {
    color: white;
    margin-top: 10px;
    margin-left: 20px;
    font: 20px / 38px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.header-actions {
    display: flex;
    align-items: center;
}

.burger-menu {
    display: none;
    cursor: pointer;
}
.logotag{
    display: flex;
    margin-left: -219px;
    font-size: 13px;
    font-weight: 600;
    transform: translateY(45px);
}


@media (max-width: 1140px) {
    .header-nav {
        display: none;
    }

    .login-button{
        display: none;
    }

    .mobile-login{
        color: #333;
        background-color: #fff;
            font-size: 19px;
            font-weight: 600;
            border: none;
            padding: 7px ;
    }

    .burger-menu {
        display: flex;
        margin-left: 2rem;
        cursor: pointer;
        width: 70%;
        height: 70%;
    }

    .header-actions {
        flex-direction: row;
    }

    .mobile-header {
        display: flex;
        justify-content: flex-end;
        /* Aligns the close button to the right */
        padding: 10px;
    }

    .close-mobile-nav {
        background: none;
        border: none;
        font-size: 24px;
        color: #333;
        /* Change to match your theme */
        cursor: pointer;
    }

    .mobile-nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }

    .mobile-nav .nav-link {
        margin: 10px 0;
        text-align: center;
        cursor: pointer;
    }

    .popup-content {
        width: 70%;
        /* Set width to 90% of the viewport width */
        height: 90vh;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }

    .popup-content span,
    .popup-content h2,
    .popup-content p,
    .popup-content form {
        width: 100%;
        /* Make the content full width */
        margin: 15px auto;
        /* Center the content */
    }

    .popup-heading {
        margin-top: 1rem;
        /* Move the heading up */
    }

    .popup-text {
        margin-top: 0.5rem;
        /* Adjust the margin for the paragraph */
    }

    .error-message {
        color: white;
        margin-top: 10px;
        /* Keep some space above */
        margin-bottom: 5px;
        /* Reduced bottom margin */
        font: 20px / 38px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
    }

    .login-box {
        margin-bottom: 20px;
        /* Adjust to suit layout */
    }

    .input-container .login-input {
        margin-top: 1rem;
        /* Reduced top margin for input fields */
    }
    .logotag{
        display: none;
    }
}


@media (max-width: 933px) {
    .popup-content {
        width: 73%;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: none;
    }
}

@media (max-width: 890px) {
    .popup-content {
        width: 75%;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: none;
    }
}

@media (max-width: 870px) {
    .popup-content {
        width: 79%;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: none;
    }
}

@media (max-width: 825px) {
    .popup-content {
        width: 80%;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: none;
    }
}

@media (max-width: 815px) {
    .popup-content {
        width: auto;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: none;
    }
}

@media (max-width: 780px) {
    .popup-content {
        width: 75%;
        /* Set width to 90% of the viewport width */
        height: auto;
        /* Set a smaller max-height */
        padding: 2rem;
        /* Adjust padding for smaller screens */
        margin: 0 auto;
        /* Center the popup */
    }
    .logotag{
        display: flex;
        font-size: 10px;
        font-weight: 700;
    }
}

@media (max-width: 600px) {
    .input-container .login-input {
        margin-top: 2rem;
        margin-left: 15px;
        font-size: 16px;
        border: 1px solid #ffa500;
        background-color: #2a2e45;
        color: white;
        width: 92%;
        padding-top: 7px;
        padding-left: 35px;
        padding-bottom: 5px;
        outline: none;
    }

        .sign-in-button {
            width: 85%;
            border-radius: 33px;
            box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
            margin-top: 28px;
            align-items: center;
            font-weight: 700;
            text-transform: uppercase;
            justify-content: center;
            padding: 26px 60px;
            flex-wrap: wrap;
            background-color: #ffa500;
            color: white;
            border: none;
            margin-left: 20px;
            margin-bottom: 10px;
            cursor: pointer;
    
        }
        
}

@media (max-width: 500px) {
    .input-container .login-input {
        margin-top: 2rem;
        margin-left: 11px;
        font-size: 14px;
        border: 1px solid #ffa500;
        background-color: #2a2e45;
        color: white;
        width: 92%;
        padding-top: 7px;
        padding-left: 40px;
        padding-bottom: 5px;
        outline: none;
    }

        .sign-in-button {
            width: 85%;
            border-radius: 33px;
            box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
            margin-top: 28px;
            align-items: center;
            font-weight: 700;
            text-transform: uppercase;
            justify-content: center;
            padding: 26px 60px;
            flex-wrap: wrap;
            background-color: #ffa500;
            color: white;
            border: none;
            margin-left: 20px;
            margin-bottom: 10px;
            cursor: pointer;
    
        }
       
}

@media (max-width: 400px) {
    .logotag{
        display: flex;
        font-size: 12px;
        font-weight: 700;
    }
    .input-container .login-input {
        margin-top: 2rem;
        margin-left: 13px;
        font-size: 14px;
        border: 1px solid #ffa500;
        background-color: #2a2e45;
        color: white;
        width: 91%;
        padding-top: 9px;
        padding-left: 39px;
        padding-bottom: 7px;
        outline: none;
    }
    
        .sign-in-button {
            width: 85%;
            border-radius: 33px;
            box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
            margin-top: 28px;
            align-items: center;
            font-weight: 700;
            text-transform: uppercase;
            justify-content: center;
            padding: 26px 60px;
            flex-wrap: wrap;
            background-color: #ffa500;
            color: white;
            border: none;
            margin-left: 20px;
            margin-bottom: 10px;
            cursor: pointer;
    
        }
        
}

@media (max-width: 370px) {
    .logotag{
        display: flex;
        font-size: 10px;
        font-weight: 700;
    }


    .input-container .login-input {
        margin-top: 2rem;
        margin-left: 9px;
        font-size: 14px;
        border: 1px solid #ffa500;
        background-color: #2a2e45;
        color: white;
        width: 91%;
        padding-top: 9px;
        padding-left: 30px;
        padding-bottom: 7px;
        outline: none;
    }

    .icon1,
    .icon2{
        left: 15px;
    }

    .sign-in-button {
        width: 85%;
        border-radius: 33px;
        box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
        margin-top: 28px;
        align-items: center;
        font-weight: 700;
        text-transform: uppercase;
        justify-content: center;
        padding: 26px 60px;
        flex-wrap: wrap;
        background-color: #ffa500;
        color: white;
        border: none;
        margin-left: 20px;
        margin-bottom: 10px;
        cursor: pointer;

    }
   
}

@media (max-width: 350px) {
    .logotag{
        display: flex;
        font-size: 6px;
        font-weight: 700;
    }
    .input-container .login-input {
        margin-top: 2rem;
        margin-left: 9px;
        font-size: 12px;
        border: 1px solid #ffa500;
        background-color: #2a2e45;
        color: white;
        width: 91%;
        padding-top: 9px;
        padding-left: 30px;
        padding-bottom: 7px;
        outline: none;
    }

    .icon1,
    .icon2 {
        left: 15px;
    }

    .sign-in-button {
        width: 85%;
        border-radius: 33px;
        box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
        margin-top: 28px;
        align-items: center;
        font-weight: 700;
        text-transform: uppercase;
        justify-content: center;
        padding: 26px 60px;
        flex-wrap: wrap;
        background-color: #ffa500;
        color: white;
        border: none;
        margin-left: 20px;
        margin-bottom: 10px;
        cursor: pointer;

    }
   
}

