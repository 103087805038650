.social-icons {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 220px;
    left: 15px;
    display: flex;
    gap: 15px;
    font-size: 30px;
    z-index: 10;
}