.news-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 160px;
}

.news-image-container {
    position: relative;
    transform: translateY(-230px);
    margin-bottom: 400px;
}

.news-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.news-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 30px;
    text-align: center;
    z-index: 5;
}
.coming-soon-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    padding: 20px 0;
    background-color: rgba(255, 254, 254, 0.5); /* Optional background color */
    margin-bottom: 623px;
}

.coming-soon-text {
    font-size: 24px;
    color: #000000; /* White text for visibility */
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 50px;
}


@media only screen and (max-width:769px){
    .news-image-container {
        position: relative;
        transform: translateY(1px);
        margin-bottom: 400px;
    }

    .news-heading{
        margin-top: 10px;
    }

    .coming-soon-text {
        font-size: 24px; 
        color: #000000; 
        text-align: center; 
        transform: translateY(250px); 
        font-weight: bold;
    }
}

@media only screen and (max-width:480px) {
    .news-heading{
        width: 100%;
        margin-top: 50px;
    }
}