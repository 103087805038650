.contact-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
}

.contact-image-container {
    position: relative;
    transform: translateY(-230px);
    /* margin-bottom: 300px; */
}

.contact-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.contact-heading {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, 30%);
    color: #ffa500;
    font-size: 30px;
    text-align: center;
    z-index: 5;
}

.contact-content {
    display: flex;
    justify-content: center;
    padding: 100px;
    transform: translateY(-270px);
    margin-bottom: -370px;
}

.contact-subheading {
    font-size: 18px;
    width: 80%;
    text-align: center;
    margin-bottom: 20px;
    transform: translateY(-50px) translateX(130px);
}

.contact-info {
    flex: 1;
    margin-right: 20px;
    transform: translateY(-50px);
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 20px;
    gap: 10px;
    transform: translateY(-20px);
}

.contact-icon {
    padding: 15px 17px;
    height: 12%;
    width: 12%;
    background-color: #ffa500;
    color: #fff;
    box-sizing: border-box;
    border-radius: 12px;
}

.contact-address-icon {
    padding: 11px 11px;
    height: 13%;
    width: 12%;
    background-color: #ffa500;
    color: #fff;
    box-sizing: border-box;
    margin-top: -32px;
    border-radius: 12px;
}

.contact-address {
    width: 90%;
    transform: translateX(1);
}

.contact-item h3,
.contact-item p {
    font-size: 18px;
    margin: 0 0 5px 10px;
}

.contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    transform: translateY(-80px);
}

.phone-container .code {
    width: 30%;
}

.phone-container .phn {
    width: 70%;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #7a7979;
    border-radius: 20px;
    font-size: 18px;
    width: 90%;
}

.phone-container {
    display: flex;
    align-items: center;
    width: 90%;
}

.phone-container select {
    margin-right: 10px;
    width: 80px;
}


.code {
    margin-right: 10px; /* Adds space between country code and phone input */
  }

.error {
    color: red;
    font-size: 16px;
    padding-bottom: 10px;
}

.error-input {
    border: 2px solid red;
    /* Highlight border */
}

.send-button {
    background-color: #ffa500;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    width: 40%;
    font-size: 12px;
    margin-left: 125px;
}

.send-button:hover {
    background-color: #e69500;
}

.success-message {
    margin-bottom: 2rem;
    color: #000000;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
}

.address-link{
    text-decoration: none;
    color: inherit; /* Keeps the text color unchanged */
}

@media only screen and (max-width: 1025px) {
    .contact-content {
        flex-direction: column;
        top: 18%;
        width: 90%;
        margin-left: -30px;
        text-align: start;
    }

    .contact-address {
        width: 110%;
    }

    .contact-address-icon {
        padding: 10px 12px;
        height: 8%;
        width: 8%;
    }

    .contact-icon {
        padding: 10px 12px;
        height: 6.5%;
        width: 6.5%;
    }

    .contact-form input,
    .contact-form select,
    .contact-form button {
        width: 110%;
    }

    .phone-container {
        width: 110%;
    }

    .contact-form .message-box {
        width: 110%;
    }
    .send-button{
        margin-left: 1px;

    }
}

@media only screen and (max-width: 950px) {
    .contact-heading {
        top: 65%;
    }

    .contact-subheading {
        margin-top: 80px;
        margin-left: -50px;
    }

    .contact-address-icon {
        padding: 12px 14px;
        height: 9%;
        width: 9%;
    }

    .contact-icon {
        padding: 12px 14px;
        height: 7.5%;
        width: 7.5%;
    }
}

@media only screen and (max-width: 900px) {
    .contact-address-icon {
        padding: 11px 10px;
        height: 12%;
        width: 8%;
    }

    .contact-icon {
        padding: 13px 15px;
        height: 8.5%;
        width: 8.5%;
    }
}

@media only screen and (max-width: 870px) {
    .contact-heading {
        top: 65%;
    }

    .contact-subheading {
        margin-top: 120px;
    }

    .contact-address-icon {
        padding: 16px 16px;
        height: 14%;
        width: 10%;
    }

    .contact-icon {
        padding: 14px 16px;
        height: 9.5%;
        width: 9.5%;
    }

    .contact-form {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 800px) {
    .contact-subheading {
        margin-top: 150px;
    }

    .contact-address-icon {
        padding: 16px 16px;
        height: 16%;
        width: 12%;
    }

    .contact-icon {
        padding: 14px 16px;
        height: 11%;
        width: 11%;
    }

    .contact-form {
        margin-top: 30px;
    }
    .send-button{
        margin-left: 1px;

    }


}

@media only screen and (max-width: 700px) {
    .contact-subheading {
        margin-top: 210px;
    }

    .contact-address-icon {
        padding: 16px 16px;
        height: 18%;
        width: 13%;
    }

    .contact-icon {
        padding: 14px 16px;
        height: 13%;
        width: 13%;
    }
    .send-button{
        margin-left: 1px;

    }

}

@media only screen and (max-width: 650px) {
    .contact-heading {
        top: 60%;
    }

    .contact-subheading {
        margin-top: 250px;
        margin-left: -90px;
    }

    .contact-address-icon {
        padding: 10px 12px;
        height: 10%;
        width: 20%;
        margin-top: -30px;
    }

    .contact-icon {
        padding: 14px 16px;
        height: 18%;
        width: 18%;
    }
}

@media only screen and (max-width:622px) {
    
    .contact-address-icon {
        padding: 10px 14px;
        height: 3rem;
        width: 5rem;
        margin-top: -60px;
    }

    .contact-icon {
        padding: 14px 16px;
        height: 18%;
        width: 18%;
    }
    .send-button{
        margin-left: 1px;

    }
}
@media only screen and (max-width:580px){
    .contact-icon{
        width: 19%;
    }
    .contact-heading{
        margin-top: -10%;
        margin-bottom: -20%;
    }
    .contact-subheading{
        margin-top: 60%;
    }
}
/* @media only screen and (max-width:560px){
    .contact-heading{
        margin-top: 1%;
    }
    .contact-subheading{
        margin-top: 810px;
    }
} */


@media only screen and (max-width:500px){
    .contact-icon{
        width: 25%;
    }
}
@media only screen and (max-width:450px){
    .contact-icon{
        width: 31%;
    }
}



@media only screen and (max-width: 395px) {
    /* .contact-image-container{
        position: relative;
        transform: translateY(-10px);
        margin-bottom: 600px;
    } */
    .contact-icon{
        width: 41%;
    }

    .contact-content {
        margin-left: -35px;
    }
    .contact-heading {
        top: 63%;
        width: 100%;
    }
.contact-address-icon{
    width: 50%;
}
    .contact-subheading {
        margin-top: 330px;
        margin-left: -80px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: justify;

    }
    .contact-address{
        width: 190%;
    }

    .contact-form input,
    .contact-form select,
    .contact-form button {
        width: 200%;
    }

    .contact-form .message-box {
        width: 200%;
    }

    .contact-form .phone-container {
        width: 200%;
    }
    .send-button{
        margin-left: 1px;

    }

}


@media only screen and (max-width: 380px) {
    .contact-icon{
        width: 51%;
    }
}
@media only screen and (max-width: 360px) {
    .contact-icon{
        width: 51%;
    }
}
