.careers-wrapper {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 160px;
}

.careers-image-container {
    position: relative;
    transform: translateY(-230px);
    margin-bottom: 400px;
}

.careers-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.careers-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 30px;
    text-align: center;
    z-index: 5;
}


@media (max-width:769px){
    .careers-image-container {
        position: relative;
        transform: translateY(1px);
        margin-bottom: 400px;
    }

    .careers-heading{
        margin-top: 45px;
    }

    .coming-soon-text {
        font-size: 24px; 
        color: #000000; 
        text-align: center; 
        transform: translateY(250px); 
        font-weight: bold;
    }
}