
.global-footer {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
    background-color:#69696971;
    justify-content: space-evenly;
    margin-top: -30px;
}

.footer-sections{
    margin-top: 150px;
}

.footer-link {
    font-size: 16px;
    color: #000000;
    text-decoration: none;
    margin: 5px 0;
    display: block;
    text-align: center;
    margin-top: 10px;
}

.footer-link:hover {
    text-decoration: none;
    color: #ffa500;
}

.footer-sections {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    padding-top: 30px;
    margin: 0 20px;
    margin-bottom: -30px;
    
}

.footer-section {
    flex: 1;
    margin: 0 20px;
    
}

.footer-logo {
    width: 150px;
    margin: 25px 50px;
    margin-bottom: -10px;
    margin-top: 60px;

}

.footer-section h3 {
    font-size: 23px;
    color: #000000;
    margin-bottom: 10px;
    text-align: center;
    
}

.contact-heading1{
    font-size: 23px;
        color: #000000;
        margin-bottom: 10px;
        margin-left: 65px;
        
}
.footer-section .hour{
    font-size: 20px;
    color: #000000;

}

.footer-section p {
    font-size: 16px;
    color: #000000;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.address-para{
    font-size: 16px;
    color: #000000;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110%;
}

.section-2 {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-start;
    /* Align items at the start */
    
}

.section-2 p {
    margin: 5px 0;
    /* Adjust this value for spacing */
    width: auto;
    /* Set width to auto */
    text-align: left;
    /* Align text to the left */
    
}

.footer-section .icon {
    margin-right: 10px;
    color: #ffa500;
    
}

.address-icon{
    margin-right: 10px;
    height: 45px;
    width: 45px;
    color: #ffa500;
    
}

.address-link1 {
    text-decoration: none;
    color: inherit; /* Keeps the text color unchanged */
  }
  
  .address-link:hover {
    text-decoration: none; /* Optional hover effect */
  }
  

.footer-divider {
    width: 100%;
    height: 1.4px;
    background-color: #000000;
    margin: 30px 0;
    transform: translateY(45px);
    
}

.footer-copyright p{
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    transform: translateY(37px);
margin-left: 1000px;


}
.footer-copyright1{
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    transform: translateY(22px);
}

.footer-section{
    transform: translateY(20px);
}

.footer-policy {
    display: flex;                /* Use flexbox for alignment */
    justify-content: flex-start;  /* Align items to the left */
    padding: 10px;               /* Add some padding */
    color: #000000;                 /* Text color */
    font-size: 14px;             /* Font size */
    transform: translateY(-5px);
    font-weight: 600;

    
}

.footer-policy a {
    text-decoration: none;        /* Remove underline from link */
    color: hsl(0, 0%, 0%);                 /* Link color (same as text color) */
}

.footer-policy p {
    margin: 0;                   /* Remove default margin */
    cursor: pointer;             /* Change cursor to pointer on hover */
}

.footer-policy a:hover {
    text-decoration: underline;   /* Underline link on hover */
    color: #ffa500;               /* Change color on hover */
}

@media only screen and (max-width: 1030px){
    .address-para{
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .footer-sections{
        justify-content: space-between;
    }
    .footer-section {
        flex: 1 1 30%;
        text-align: center;
    }

    .address-icon{
        width: 37%;
        height: 37%;
        /* padding-left: 60px; */
    }
/* 
    .mail-para{
        padding-left: 10px;
    } */

    .section-1 {
        order: 1;
    }

    .section-1 p{
        margin-top: 5px;
    }

    .section-3 {
        padding-top: 20px;
        order: 3;
    }

    .section-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        order: 2;
    }

    .section-2 h3{
        margin-left: 30px;
    }

    .section-4 {
        padding-top: 20px;
        order: 4;
    }
}

@media only screen and (max-width: 877px) {
    .footer-sections {
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        align-items: center;
        /* Center align all sections */
        gap: 5px;
    }

    .footer-section {
        flex: 1 1 100%;
        /* Allow one item per row */
        text-align: center;
        margin-bottom: 20px;
    }

    .section-2 {
        text-align: center;
        width: 40%;
    }

    .address-icon{
        width: 20%;
        height: 20%;
    }

    .footer-divider {
        transform: translateY(-20px);
        margin: 30px auto;
    }

    .footer-copyright {
        text-align: center;
        transform: translateY(-50px);
    }

    .footer-policy {
        justify-content: center;
        transform: translateY(-30px);
    }
}
@media only screen and (max-width: 876px){
    global-footer {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 40px;
        background-color:#69696971;
        justify-content: space-evenly;
        margin-top: -30px;
    }
    .footer-section, .section-1{
        margin-left: -300px;
    }
    .footer-sections{
        margin-top: 150px;
    }
    
    .footer-link {
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 5px 0;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    
    .footer-link:hover {
        text-decoration: none;
        color: #ffa500;
    }
    
    .footer-sections {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        padding-top: 30px;
        margin: 0 20px;
        margin-bottom: -30px;
        margin-left: 5px;        
    }
    
    .footer-section {
        flex: 1;
        margin: 0 20px;
        
    }

    .address-para{
        margin-left: -110px;
    }
    .footer-logo {
        width: 150px;
        margin: 25px 50px;
        margin-bottom: -10px;
        margin-top: 20px;
    
    }
    
    .footer-section h3 {
        font-size: 23px;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
        
    }
    
    .contact-heading1 h3{
        font-size: 23px;
        font-weight: 600px;
            color: #000000;
            margin-bottom: 10px;
            margin-left: 65px;
            
    }
    .footer-section .hour{
        font-size: 20px;
        color: #000000;
    
    }
    
    .footer-section p {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .address-para{
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110%;
    }
    
    .section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
        
    }
    
    .section-2 p {
        margin: 5px 0;
        /* Adjust this value for spacing */
        width: auto;
        /* Set width to auto */
        text-align: left;
        /* Align text to the left */
        
    }
    
    .footer-section .icon {
        margin-right: 10px;
        color: #ffa500;
        
    }
    
    .address-icon{
        margin-right: 10px;
        height: 45px;
        width: 45px;
        color: #ffa500;
        
    }
    
    .address-link1 {
        text-decoration: none;
        color: inherit; /* Keeps the text color unchanged */
      }
      
      .address-link1:hover {
        text-decoration: none; /* Optional hover effect */
      }
      
    
    .footer-divider {
        width: 100%;
        height: 1.4px;
        background-color: #000000;
        margin: 30px 0;
        transform: translateY(25px);
        
    }
    
    .footer-copyright p{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateY(37px);
    margin-left: 10px;
    margin-top: 20px;
    
    
    }
    .footer-copyright1{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateX(10px);
        margin-top: 50px;
        margin-bottom: -10px;
    }
    
    .footer-section{
        transform: translateY(20px);
    }
    
    .footer-policy {
        display: flex;                /* Use flexbox for alignment */
        justify-content: flex-start;  /* Align items to the left */
        padding: 10px;               /* Add some padding */
        color: #000000;                 /* Text color */
        font-size: 14px;             /* Font size */
        transform: translateY(-90px);
        margin-top: 10%;
        margin-left: 330px;
        font-weight: 600;
        margin-top: 30px;
    
        
    }
    
    .footer-policy a {
        text-decoration: none;        /* Remove underline from link */
        color: hsl(0, 0%, 0%);                 /* Link color (same as text color) */
    }
    
    .footer-policy p {
        margin: 0;                   /* Remove default margin */
        cursor: pointer;             /* Change cursor to pointer on hover */
    }
    
    .footer-policy a:hover {
        text-decoration: underline;   /* Underline link on hover */
        color: #ffa500;               /* Change color on hover */
    }
}

@media only screen and (max-width: 650px){
    global-footer {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 40px;
        background-color:#69696971;
        justify-content: space-evenly;
        margin-top: -30px;
    }
    .footer-section, .section-1{
        margin-left: -300px;
    }
    .footer-sections{
        margin-top: 150px;
    }
    
    .footer-link {
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 5px 0;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    
    .footer-link:hover {
        text-decoration: none;
        color: #ffa500;
    }
    
    .footer-sections {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        padding-top: 30px;
        margin: 0 20px;
        margin-bottom: -30px;
        margin-left: 5px;        
    }
    
    .footer-section {
        flex: 1;
        margin: 0 20px;
        
    }

    .address-para{
        margin-left: -110px;
    }
    .footer-logo {
        width: 150px;
        margin: 25px 50px;
        margin-bottom: -10px;
        margin-top: 20px;
    
    }
    
    .footer-section h3 {
        font-size: 23px;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
        
    }
    
    .contact-heading1 h3{
        font-size: 23px;
        font-weight: 600px;
            color: #000000;
            margin-bottom: 10px;
            margin-left: 65px;
            
    }
    .footer-section .hour{
        font-size: 20px;
        color: #000000;
    
    }
    
    .footer-section p {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .address-para{
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110%;
    }
    
    .section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
        
    }
    
    .section-2 p {
        margin: 5px 0;
        /* Adjust this value for spacing */
        width: auto;
        /* Set width to auto */
        text-align: left;
        /* Align text to the left */
        
    }
    
    .footer-section .icon {
        margin-right: 10px;
        color: #ffa500;
        
    }
    
    .address-icon{
        margin-right: 10px;
        height: 45px;
        width: 45px;
        color: #ffa500;
        
    }
    
    .address-link1 {
        text-decoration: none;
        color: inherit; /* Keeps the text color unchanged */
      }
      
      .address-link1:hover {
        text-decoration: none; /* Optional hover effect */
      }
      
    
    .footer-divider {
        width: 100%;
        height: 1.4px;
        background-color: #000000;
        margin: 30px 0;
        transform: translateY(25px);
        
    }
    
    .footer-copyright p{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateY(37px);
    margin-left: 10px;
    margin-top: 20px;
    
    
    }
    .footer-copyright1{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateX(10px);
        margin-top: 50px;
        margin-bottom: -10px;
    }
    
    .footer-section{
        transform: translateY(20px);
    }
    
    .footer-policy {
        display: flex;                /* Use flexbox for alignment */
        justify-content: flex-start;  /* Align items to the left */
        padding: 10px;               /* Add some padding */
        color: #000000;                 /* Text color */
        font-size: 14px;             /* Font size */
        transform: translateY(-90px);
        margin-top: 10%;
        margin-left: 230px;
        font-weight: 600;
        margin-top: 30px;
    
        
    }
    
    .footer-policy a {
        text-decoration: none;        /* Remove underline from link */
        color: hsl(0, 0%, 0%);                 /* Link color (same as text color) */
    }
    
    .footer-policy p {
        margin: 0;                   /* Remove default margin */
        cursor: pointer;             /* Change cursor to pointer on hover */
    }
    
    .footer-policy a:hover {
        text-decoration: underline;   /* Underline link on hover */
        color: #ffa500;               /* Change color on hover */
    }
    
}

@media only screen and (max-width: 450px) {
    global-footer {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 40px;
        background-color:#69696971;
        justify-content: space-evenly;
        margin-top: -30px;
    }
    .footer-section, .section-1{
        margin-left: -300px;
    }
    .footer-sections{
        margin-top: 150px;
    }
    
    .footer-link {
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 5px 0;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    
    .footer-link:hover {
        text-decoration: none;
        color: #ffa500;
    }
    
    .footer-sections {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        padding-top: 30px;
        margin: 0 20px;
        margin-bottom: -30px;
        margin-left: 5px;        
    }
    
    .footer-section {
        flex: 1;
        margin: 0 20px;
        
    }

    .address-para{
        margin-left: -110px;
    }
    .footer-logo {
        width: 150px;
        margin: 25px 50px;
        margin-bottom: -10px;
        margin-top: 20px;
    
    }
    
    .footer-section h3 {
        font-size: 23px;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
        
    }
    
    .contact-heading1 h3{
        font-size: 23px;
        font-weight: 600px;
            color: #000000;
            margin-bottom: 10px;
            margin-left: 65px;
            
    }
    .footer-section .hour{
        font-size: 20px;
        color: #000000;
    
    }
    
    .footer-section p {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .address-para{
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110%;
    }
    
    .section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
        
    }
    
    .section-2 p {
        margin: 5px 0;
        /* Adjust this value for spacing */
        width: auto;
        /* Set width to auto */
        text-align: left;
        /* Align text to the left */
        
    }
    
    .footer-section .icon {
        margin-right: 10px;
        color: #ffa500;
        
    }
    
    .address-icon{
        margin-right: 10px;
        height: 45px;
        width: 45px;
        color: #ffa500;
        
    }
    
    .address-link1 {
        text-decoration: none;
        color: inherit; /* Keeps the text color unchanged */
      }
      
      .address-link1:hover {
        text-decoration: none; /* Optional hover effect */
      }
      
    
    .footer-divider {
        width: 100%;
        height: 1.4px;
        background-color: #000000;
        margin: 30px 0;
        transform: translateY(25px);
        
    }
    
    .footer-copyright p{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateY(37px);
    margin-left: 10px;
    margin-top: 20px;
    
    
    }
    .footer-copyright1{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateX(10px);
        margin-top: 50px;
        margin-bottom: -10px;
    }
    
    .footer-section{
        transform: translateY(20px);
    }
    
    .footer-policy {
        display: flex;                /* Use flexbox for alignment */
        justify-content: flex-start;  /* Align items to the left */
        padding: 10px;               /* Add some padding */
        color: #000000;                 /* Text color */
        font-size: 14px;             /* Font size */
        transform: translateY(-90px);
        margin-top: 10%;
        margin-left: 100px;
        font-weight: 600;
        margin-top: 30px;
    
        
    }
    
    .footer-policy a {
        text-decoration: none;        /* Remove underline from link */
        color: hsl(0, 0%, 0%);                 /* Link color (same as text color) */
    }
    
    .footer-policy p {
        margin: 0;                   /* Remove default margin */
        cursor: pointer;             /* Change cursor to pointer on hover */
    }
    
    .footer-policy a:hover {
        text-decoration: underline;   /* Underline link on hover */
        color: #ffa500;               /* Change color on hover */
    }
    
}


@media only screen and (max-width: 395px) {
    global-footer {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 10px 40px;
        background-color:#69696971;
        justify-content: space-evenly;
        margin-top: -30px;
    }
    .footer-section, .section-1{
        margin-left: -300px;
    }
    .footer-sections{
        margin-top: 150px;
    }
    
    .footer-link {
        font-size: 16px;
        color: #000000;
        text-decoration: none;
        margin: 5px 0;
        display: block;
        text-align: center;
        margin-top: 10px;
    }
    
    .footer-link:hover {
        text-decoration: none;
        color: #ffa500;
    }
    
    .footer-sections {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        padding-top: 30px;
        margin: 0 20px;
        margin-bottom: -30px;
        margin-left: 5px;        
    }
    
    .footer-section {
        flex: 1;
        margin: 0 20px;
        
    }

    .section-2{
        width: 100%;
    }
 .address-link1{ 
    width: 100%;
    margin-left: 40px;
}

    .address-para{
        margin-left: -110px;
    }
    .footer-logo {
        width: 150px;
        margin: 25px 50px;
        margin-bottom: -10px;
        margin-top: 20px;
    
    }
    
    .footer-section h3 {
        font-size: 23px;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
        
    }
    
    .contact-heading1 h3{
        font-size: 23px;
        font-weight: 600px;
            color: #000000;
            margin-bottom: 10px;
            margin-left: 65px;
            
    }
    .footer-section .hour{
        font-size: 20px;
        color: #000000;
    
    }
    
    .footer-section p {
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .address-para{
        font-size: 16px;
        color: #000000;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110%;
    }
    
    .section-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: -20px;
        
    }
    
    .section-2 p {
        margin: 5px 0;
        /* Adjust this value for spacing */
        width: auto;
        /* Set width to auto */
        text-align: left;
        /* Align text to the left */
        
    }
    
    .footer-section .icon {
        margin-right: 10px;
        color: #ffa500;
        
    }
    
    .address-icon{
        margin-right: 10px;
        height: 45px;
        width: 45px;
        color: #ffa500;
        
    }
    
    .address-link1{
        text-decoration: none;
        color: inherit; /* Keeps the text color unchanged */
      }
      
      .address-link1:hover {
        text-decoration: none; /* Optional hover effect */
      }
      
    
    .footer-divider {
        width: 100%;
        height: 1.4px;
        background-color: #000000;
        margin: 30px 0;
        transform: translateY(25px);
        
    }
    
    .footer-copyright p{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateY(37px);
    margin-left: 10px;
    margin-top: 20px;
    
    
    }
    .footer-copyright1{
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        transform: translateX(10px);
        margin-top: 50px;
        margin-bottom: -10px;
    }
    
    .footer-section{
        transform: translateY(20px);
    }
    
    .footer-policy {
        display: flex;                /* Use flexbox for alignment */
        justify-content: flex-start;  /* Align items to the left */
        padding: 10px;               /* Add some padding */
        color: #000000;                 /* Text color */
        font-size: 14px;             /* Font size */
        transform: translateY(-90px);
        margin-top: 10%;
        margin-left: 100px;
        font-weight: 600;
        margin-top: 30px;
    
        
    }
    
    .footer-policy a {
        text-decoration: none;        /* Remove underline from link */
        color: hsl(0, 0%, 0%);                 /* Link color (same as text color) */
    }
    
    .footer-policy p {
        margin: 0;                   /* Remove default margin */
        cursor: pointer;             /* Change cursor to pointer on hover */
    }
    
    .footer-policy a:hover {
        text-decoration: underline;   /* Underline link on hover */
        color: #ffa500;               /* Change color on hover */
    }
    
}