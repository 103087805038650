.policy-content {
    height: auto;
    position: relative;
    overflow: hidden;
    padding-bottom: 300px;
}

.policy-image-container {
    position: relative;
    transform: translateY(-230px);
    margin-bottom: 400px;
}

.policy-background-image {
    width: 100%;
    height: 100%;
    background-color: rgb(234, 233, 233);
}

.policy-heading {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 60px;
    text-align: center;
    z-index: 5;
    margin-bottom: 0;
}

.policy-container {
    padding: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    /* background-color: #f9f9f9c9; */
    transform: translateY(-640px);
    justify-content: center;
    align-items: center;
    margin-bottom: -950px;
}

.policy-container h1,
.policy-container h2 {
    font-weight: bold;
    margin-top: 20px;
    margin-left: 70px;
}

.policy-container p,
.policy-container ul {
    margin-bottom: 15px;
    padding: 0 50px;
    margin-left: 26px;
}


@media (max-width: 1100px) {
    .policy-heading {
        font-size: 40px;
        /* Decrease heading size */
        top: 85%;
        /* Adjust position */
    }

    .policy-container {
        padding: 10px;
        /* Adjust padding */
        margin-top: 50px;
        margin-left: 50px;
    }

    .policy-container h1,
    .policy-container h2 {
        margin-left: 50px;
        /* Reduce margin */
    }

         .policy-container p,
         .policy-container ul {
             padding: 0 20px;
             /* Reduce padding */
             margin-left: 30px;
             /* Reduce left margin */
         }
                 .policy-container ul {
                     padding: 0 20px;
                     /* Reduce padding */
                     margin-left: 50px;
                     /* Reduce left margin */
                 }
}


@media (max-width: 992px){
.policy-heading {
        font-size: 30px;
        /* Decrease heading size */
        top: 110%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 90px;
        margin-left: 50px;
    }

    .policy-container h1,
    .policy-container h2 {
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 22px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}

@media (max-width: 768px) {
    .policy-heading {
        font-size: 30px;
        /* Decrease heading size */
        top: 135%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 130px;
        margin-left: 50px;
    }

    .policy-container h1,
    .policy-container h2 {
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 19px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}

@media (max-width: 660px) {
    .policy-heading {
        font-size: 30px;
        /* Decrease heading size */
        top: 155%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 190px;
        margin-left: 50px;
    }

    .policy-container h1,
    .policy-container h2 {
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 20px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}

@media (max-width: 550px) {
    .policy-heading {
        font-size: 30px;
        /* Decrease heading size */
        top: 195%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 250px;
        margin-left: 30px;
    }

    .policy-container h1,
    .policy-container h2 {
        font-size: 23px;
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 20px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}

@media (max-width: 450px) {
    .policy-heading {
        font-size: 30px;
        /* Decrease heading size */
        top: 230%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 290px;
        margin-left: 30px;
    }

    .policy-container h1,
    .policy-container h2 {
        font-size: 21px;
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 19px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}
@media (max-width: 395px) {
.policy-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.policy-container{
    margin-top: -10px;
        margin-left:12px;
        width: 110%;
        
}

}
@media (max-width: 390px) {
    .policy-heading {
        font-size: 25px;
        /* Decrease heading size */
        top: 260%;
        /* Adjust position */
    }

    .policy-container {
        padding-top: 290px;
        margin-left: 20px;
    }

    .policy-container h1,
    .policy-container h2 {
        font-size: 20px;
        margin-left: 50px;
        /* Reduce margin */
    }

    .policy-container p,
    .policy-container ul {
        font-size: 19px;
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 30px;
        /* Reduce left margin */
    }

    .policy-container ul {
        padding: 0 20px;
        /* Reduce padding */
        margin-left: 50px;
        /* Reduce left margin */
    }
}