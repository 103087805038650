.slider-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #fffafa; /* Light background color */
    padding: 40px 0; /* Add vertical padding to ensure cards are centered */
    border-radius: 16px; /* Rounded corners for the container */
    margin-top: -360px;
    margin-bottom: -70px;
  }
  
  .slider-card {
    background: #ffffff; /* Dark background for the card */
    color: #ffffff; /* Light text color for contrast */
    padding: 20px; /* Adjust padding to fit content */
    border-radius: 30px; /* Rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 54, 21, 0.54);
    text-align: center; /* Center-align text */
    position: relative;
    width: auto; /* Fixed width for a smaller card */
    height: 150px; /* Adjust height to match a phone's aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out content */
    overflow: hidden;
  }
  
  .card-footer {
    display: flex;
    align-items: center; /* Center items vertically */
    margin-top: 10px;
    justify-content: center; /* Center content horizontally */
  }
  .card1{
    width: 70%; /* Fixed width for the circular image */
    object-fit: fill; /* Ensure the image covers the area */
    margin-top: 10px;
  }
  .card-avatar {
    width: 60%; /* Fixed width for the circular image */
    object-fit: fill; /* Ensure the image covers the area */
  }
  .card3{
    width: 50%; /* Fixed width for the circular image */
    object-fit: fill; /* Ensure the image covers the area */
    margin-top: -10px;
  }
  .card4{
    width: 72%; /* Fixed width for the circular image */
    object-fit: fill; /* Ensure the image covers the area */
    margin-top: -15px;
  }
  .card5{
    width: 60%; /* Fixed width for the circular image */
    object-fit: fill; /* Ensure the image covers the area */
    margin-top: -15px;
  }
  
  .slick-slide {
    transition: transform 0.5s ease;
  }
  
  .slick-slide.slick-center .slider-card {
    transform: scale(0.95); /* Slightly enlarge the centered card */
  }
  
  .slick-slide:not(.slick-center) .slider-card {
    transform: scale(0.8); /* Slightly shrink non-centered cards */
  }
  
  .slick-prev, .slick-next {
    z-index: 2;
    cursor: pointer;
    color: #014A39; /* Color for navigation arrows */
  }
  
  .slick-prev {
    left: 10px; /* Position left arrow */
  }
  
  .slick-next {
    right: 10px; /* Position right arrow */
  }
  .slider-element{
    color: #000;
    margin-bottom: 20px;
    margin-left: 70px;
  }

  @media (max-width: 1550px){
    .card4, 
    .card5{
      margin-top: -30px;
    }
  }

    @media (max-width: 1440px) {
      .slider-card {
        width: 350px;
        /* Adjusted width */
        height: 200px;
        /* Adjusted height */
      }
  
      .card1{
        width: 80%;/* Make images take full width of the card */
        height: auto;/* Maintain aspect ratio */
         object-fit: contain;
         margin-top: 30px;
      }
      .card-avatar{
        width: 80%;/* Make images take full width of the card */
        height: auto;/* Maintain aspect ratio */
         object-fit: contain;
         margin-top: 20px;
      }
.card3{
  width: 80%;/* Make images take full width of the card */
  height: auto;/* Maintain aspect ratio */
   object-fit: contain;
}
.card4{
  width: 80%;/* Make images take full width of the card */
  height: auto;/* Maintain aspect ratio */
   object-fit: contain;
   margin-top: 10px;
}
.card5{
  width: 80%;/* Make images take full width of the card */
  height: auto;/* Maintain aspect ratio */
   object-fit: contain;
   margin-top: -10px;
}
   
    }

    @media (max-width: 1200px) {
      .slider-card {
        width: 350px;
        /* Adjusted width */
        height: 200px;
        /* Adjusted height */
      }

            .card1,
            .card-avatar,
            .card3,
            .card4,
            .card5 {
              width: 100%;
              /* Make images take full width of the card */
              height: auto;
              /* Maintain aspect ratio */
              object-fit: contain;
              /* Adjust as needed */
            }
    }
  
    @media (max-width: 992px) {
      .slider-card {
        width: 300px;
        /* Further adjusted width */
        height: 210px;
        /* Further adjusted height */
      }

            .card1,
            .card-avatar,
            .card3,
            .card4,
            .card5 {
              width: 100%;
              /* Make images take full width of the card */
              height: auto;
              /* Maintain aspect ratio */
              object-fit: contain;
              /* Adjust as needed */
            }
    }
  
    @media (max-width: 768px) {
      .slider-card {
        width: 100%;
        /* Width for mobile */
        height: 220px;
        /* Height for mobile */
      }

            .card1,
            .card-avatar,
            .card3,
            .card4,
            .card5 {
              width: 90%;
              /* Make images take full width of the card */
              height: auto;
              /* Maintain aspect ratio */
              object-fit: contain;
              /* Adjust as needed */
            }
    }

     @media (max-width: 650px) {
       .slider-card {
         width: 100%;
         /* Adjusted width */
         height: 220px;
         /* Adjusted height */
       }

             .card1,
             .card-avatar,
             .card3,
             .card4,
             .card5 {
               width: 120%;
               /* Make images take full width of the card */
               height: auto;
               /* Maintain aspect ratio */
               object-fit: contain;
               /* Adjust as needed */
             }
     }
  
     @media (max-width: 550px) {
       .slider-card {
         width: 100%;
         /* Further adjusted width */
         height: 220px;
         /* Further adjusted height */
       }

             .card1,
             .card-avatar,
             .card3,
             .card4,
             .card5 {
               width: 70%;
               /* Make images take full width of the card */
               height: auto;
               /* Maintain aspect ratio */
               object-fit: contain;
               /* Adjust as needed */
             }
     }
  
     @media (max-width: 450px) {
       .slider-card {
         width: 100%;
         /* Further adjusted width */
         height: 200px;
         /* Further adjusted height */
       }

             .card1,
             .card-avatar,
             .card3,
             .card4,
             .card5 {
               width: 90%;
               /* Make images take full width of the card */
               height: auto;
               /* Maintain aspect ratio */
               object-fit: contain;
               /* Adjust as needed */
             }
     }
  
     @media (max-width: 350px) {
       .slider-card {
         width: 100%;
         /* Width for very small screens */
         height: 170px;
         /* Height for very small screens */
       }

             .card1,
             .card-avatar,
             .card3,
             .card4,
             .card5 {
               width: 100%;
               /* Make images take full width of the card */
               height: auto;
               /* Maintain aspect ratio */
               object-fit: contain;
               /* Adjust as needed */
             }
     }